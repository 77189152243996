import React, { useEffect, useState, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import BackButton from "../../Common/BackButton";
import "./ExpenseClaim.css";
import CommonButton from "../../Common/CommonButton";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { managerExpensesApi, managerExpensesNextApi, fetchAttachmentUrls, fetchBlobBase64 } from "../../services/expenses/managerExpensesApi";
import Loader from "../../Common/Loader";
import { managerClaimActionApi } from "../../services/expenses/managerClaimActionApi";
import question from "./../../assets/images/red-question-11802.svg";
import check from "./../../assets/images/check-mark-3280.svg";
import cross from "./../../assets/images/remove.png";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import Modal from "react-bootstrap/Modal";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { saveAs } from "file-saver";
import FailButton from "../../Common/FailButton";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendFirebaseNotificationApi } from "../../services/firebase/sendFirebaseNotification";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ApproveClaim = () => {
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const empDetails = useSelector((state) => state?.userDetails?.details);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [attachmentUrls, setAttachmentUrls] = useState();
  const [contentTypes, setContentTypes] = useState();
  const [rejectReason, setRejectReason] = useState();
  const [rejectId, setRejectId] = useState();
  const [rejectionReason, setRejectionReason] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [claimRemarks, setClaimRemarks] = useState([]);

  const tableRef = useRef(null);

  const getExpenses = useCallback(async () => {
    setLoading(true);
    try {
      const result = await managerExpensesApi(empDetails.Id, accessToken);
      let allClaims = []
      let batchDone = true;
      let nextRecordsUrl = "";
      if (result) {
        allClaims = result.data.records;
        batchDone = result.data.done;
        nextRecordsUrl = result.data.nextRecordsUrl;
      }

      if (!result.data.done) {
        while (batchDone === false) {
          const response = await managerExpensesNextApi(nextRecordsUrl, accessToken);
          allClaims = [...allClaims, ...response.data.records];

          setClaims(allClaims);
          batchDone = response.data.done;
          nextRecordsUrl = response.data.nextRecordsUrl;
        }
      }
      setClaims(allClaims);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch expenses', error);
      setLoading(false);
    }
  }, [empDetails.Id, accessToken]);

  // const handleClaimAction = async (claimId, status, claim) => {
  //   if (status === "Rejected" && (!rejectReason || !rejectReason.trim())) {
  //     setLoading(false);
  //     setRejectionReason(true);
  //     return;
  //   }
  //   setLoading(true);
  //   let claimObj = {
  //     expenseClaimId: claimId,
  //     Status: status,
  //     ClaimRemark: status === ("Approved" || "Released") ? '' : rejectReason,
  //     setClaims((prevClaims) =>
  //       prevClaims.map((item) =>
  //         item.Id === claimId ? { ...item, Status__c: status } : item
  //       )
  //     );

  //     await sendFirebaseNotificationApi(
  //       claim.Expense_Claim_Approval__c,
  //       "Expense Claim",
  //       `Your claim has been ${status}`,
  //       accessToken
  //     );
  //     if (status === "Approved" || status === "Released") {
  //       toast.success("Expense claim approved successfully.");
  //       getExpenses();
  //     } else if (status === "Rejected") {
  //       toast.error("Expense claim rejected successfully.");
  //       getExpenses();
  //     }
  //     else {
  //       getExpenses();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }; //     isAVP: (designation === "Associate Vice President" || designation === "General Manager") ? true : false
  //   };
  //   try {
  //     const result = await managerClaimActionApi(claimObj, accessToken);
  //     setLoading(false);
  //     setRejectModal(false);

  //     // Directly update the state without needing a full re-fetch


  const handleClaimAction = async (status) => {
    console.log("1 - Status:", status);
    console.log("Initial claimRemarks:", claimRemarks);
    console.log("Initial selectedIds:", selectedIds);

    // Check if rejection reason is required
    // if (status === "Rejected" && (!claimRemarks || claimRemarks.length === 0)) {
    //   console.log("2 - Rejection reason is missing");
    //   setLoading(false);
    //   setRejectionReason(true); // Prompt user for rejection reason
    //   return;
    // }

    setLoading(true);

    // Prepare the array of claim objects
    const claimObjects = selectedIds.map(claimId => {
      // Find the relevant remark for the current claimId
      const remark = claimRemarks.find(item => item.claimId === claimId)?.claimRemark || '';

      return {
        expenseClaimId: claimId,
        Status: status,
        ClaimRemarkRejection: status === "Rejected" ? remark : '', // For rejected claims
        ClaimRemarkApproval: status !== "Rejected" ? remark : '', // For approved or released claims
        isAVP: (designation === "Associate Vice President" || designation === "General Manager") ? true : false
      };
    });

    // Prepare the final payload
    const payload = {
      expenseClaims: claimObjects
    };

    console.log("Payload:", payload);

    try {
      console.log("3 - Sending API request");
      // Send the payload in a single API call
      const result = await managerClaimActionApi(payload, accessToken);
      console.log("result", result)
      console.log("4 - API request complete");

      // Reset claimRemarks and selectedIds state after successful API call
      setClaimRemarks([]);
      setSelectedIds([]);

      // Update the state directly without needing a full re-fetch
      setClaims((prevClaims) =>
        prevClaims.map((item) =>
          selectedIds.includes(item.Id) ? { ...item, Status__c: status } : item
        )
      );
      if (result?.data?.message.trim() === "Expense claim updated to Pending for Release") {
        toast.success("Expense claim updated to Pending for Release")
      }
      else if (result?.data?.details.trim() === "Expense claim updated to Pending for Release,Expense claim updated to Pending for Release") {
        toast.success("Expense claim updated to pending for release")
      } else if (result?.data?.details.trim() === "Expense claim rejected successfully") {
        toast.success("Expense claim rejected successfully")
      }
      else if (result?.data?.details?.trim() === "Expense claim is Approved,Expense claim is Approved") {
        toast.success("Expense claim is Approved")
      }
      else if (result?.data?.details?.trim() === "Expense claim is Approved") {
        toast.success("Expense claim is Approved")
      }
      else if (result.data?.details?.trim() === "Expense claim rejected successfully,Expense claim rejected successfully") {
        toast.success("Expense claim rejected successfully")
      }
      // Send notifications for each claim
      for (let claimObj of claimObjects) {
        await sendFirebaseNotificationApi(
          claimObj.expenseClaimId,
          "Expense Claim",
          `Your claim has been ${claimObj.Status}`,
          accessToken
        );
      }

      // Display appropriate toast messages
      if (status === "Approved" || status === "Released") {
        toast.success("Expense claims approved successfully.");
      } else if (status === "Rejected") {
        toast.error("Expense claims rejected successfully.");
      } else {
        toast.error("An error occured");
      }

      // Optionally refetch or update the list
      getExpenses();

      // Reset state here as well
      setClaimRemarks([]);
      setSelectedIds([]);
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
      // toast.error("An error occurred while processing claims.");
    } finally {
      // Ensure loading is stopped even if there's an error
      setLoading(false);
    }
  };





  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };
  const handleshowRejecteModal = (claimId, status) => {
    setRejectId(claimId);
    setRejectModal(!rejectModal);
  };
  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleApproveClick = async (urls, FileType) => {
    try {
      if (
        FileType.toLowerCase() === "png" ||
        FileType.toLowerCase() === "jpg" ||
        FileType.toLowerCase() === "jpeg"
      ) {
        setContentTypes("png"); // Set to "jpeg" as a generic type for images
        setAttachmentUrls(urls);
      } else if (FileType.toLowerCase() === "pdf") {
        setContentTypes("pdf");
        setAttachmentUrls(urls);
      } else {
        console.log("Unsupported file type");
      }

      setShowApproveModal(true);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  const downloadImage = (base64Data, contentType) => {
    const url = `data:image/${contentType};base64,${base64Data}`;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "attachment_image";
    anchor.click();
  };

  const handleDownloadPdf = () => {
    const byteCharacters = atob(attachmentUrls);
    const byteNumbers = new Array(byteCharacters?.length);
    for (let i = 0; i < byteCharacters?.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, "attachment.pdf");
  };
  const pendingMessage = `Pending - ${claims?.filter((claim) => claim.Status__c === "Pending For Approval" || claim.Status__c === "Pending for Release")?.length
    }`;
  const approvedMessage = `Approved - ${claims?.filter((claim) => claim.Status__c === "Approved" || claim.Status__c === "Released")?.length
    }`;
  const rejectedMessage = `Rejected - ${claims?.filter((claim) => claim.Status__c === "Rejected")?.length
    }`;

  useEffect(() => {
    getExpenses();
  }, [getExpenses]);

  // Download file click on View Attachment

  const handleViewAttachment = async (claimId, claimURL) => {
    setLoading(true)
    try {
      if (claimId) {
        // Fetch the attachment for the specific claim
        const attachment = await fetchAttachmentUrls(claimId, accessToken);

        if (attachment && attachment.VersionData) {
          // Convert the attachment data to base64
          const base64Data = await fetchBlobBase64(attachment.VersionData, accessToken);
          const contentType = attachment.FileType || 'application/octet-stream';

          // Find and update the specific claim
          const updatedClaims = claims.map((claim) =>
            claim.Id === claimId
              ? { ...claim, url: base64Data, contentType: contentType }
              : claim
          );

          // Update the state with the new claims array
          setClaims(updatedClaims);

          // Call handleApproveClick with the updated URL
          handleApproveClick(base64Data, attachment?.FileType);
        } else {
          // If no attachment found, pass the original claimURL
          handleApproveClick(claimURL);
        }
      }
    } catch (error) {
      console.error("Error fetching attachment:", error);
      // On error, fall back to the original claimURL
      handleApproveClick(claimURL);
    }
    setLoading(false)
  };

  const handleCheckboxChange = (dataId) => {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(dataId)) {
        // Remove the ID if it's already in the array (checkbox unchecked)
        return prevSelectedIds.filter(id => id !== dataId);
      } else {
        // Add the ID if it's not in the array (checkbox checked)
        return [...prevSelectedIds, dataId];
      }
    });
  };

  const handleInputChange = (claimId, value) => {
    setClaimRemarks(prevRemarks => {
      // Check if the ClaimId already exists in the array
      const existingIndex = prevRemarks.findIndex(item => item.claimId === claimId);

      if (existingIndex !== -1) {
        // Update the existing remark for the given ClaimId
        const updatedRemarks = [...prevRemarks];
        updatedRemarks[existingIndex].claimRemark = value;
        return updatedRemarks;
      } else {
        // Add a new entry for the ClaimId
        return [...prevRemarks, { claimId, claimRemark: value }];
      }
    });
  };


  useEffect(() => {
    console.log("IDddd--->", claimRemarks)
  }, [claimRemarks])

  return (
    <div>
      {loading && <Loader />}
      <Helmet>
        <title>Approve Claim</title>
      </Helmet>
      <div className="m-4">
        <div className="row mb-4">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="mb-2">
              <BackButton title={"Approve Claims"} />
            </div>
          </div>
        </div>
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          >
            <Tab
              eventKey="Pending"
              title={pendingMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {claims?.filter(data => data.status === "Pending For Approval")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {claims?.filter(
                    (data) => (data?.Status__c === "Pending For Approval" || data?.Status__c === "Pending for Release")
                  )?.length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Monthly Claim Status
                        </h4>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px', gap: '10px' }}>
                          <span
                            onClick={() => {
                              // const action = (designation === "Associate Vice President" || designation === "General Manager") ? "Release" : "Approved"
                              const action = claims?.some((data) => data.Status__c === "Pending for Release");
                              handleClaimAction(action ? "Release" : "Approved")
                            }}
                          >
                            <CommonButton
                              title={"Approve"}
                              width={"5rem"}
                            />
                          </span>
                          <span
                            onClick={() => handleClaimAction("Rejected")}
                          >
                            <CommonButton
                              title={"Reject"}
                              width={"5rem"}
                            />
                          </span>
                        </div>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >
                          <table
                            style={{ textAlign: "center", width: '120%' }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                <th className="custom-header">Select</th>
                                <th className="custom-header">Expense Date</th>
                                <th className="custom-header">
                                  Transaction Date
                                </th>
                                <th className="custom-header">Employee Id</th>
                                <th className="custom-header">Employee Name</th>
                                <th className="custom-header">Department</th>
                                <th className="custom-header">From Place</th>
                                <th className="custom-header">To Place</th>
                                <th className="custom-header">Distance (km)</th>
                                <th className="custom-header">Claim Type</th>
                                <th className="custom-header">
                                  Claim Sub Type
                                </th>
                                <th className="custom-header">Amount</th>
                                <th className="custom-header">
                                  View Attachment
                                </th>
                                <th className="custom-header">Status</th>
                                <th className="custom-header">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {claims?.map((Claims, index) => {
                                if (Claims.Status__c === "Pending For Approval" || Claims.Status__c === "Pending for Release") {
                                  let claimDate;
                                  let fromDate;
                                  let toDate;
                                  switch (Claims.Allowance_Type__c) {
                                    case "Local Conveyance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Travel Allowance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Daily Allowance":
                                      if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                      }
                                      else {
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.DADate__c
                                        );
                                      }
                                      break;

                                    default:
                                      claimDate = formatDateStringDDMMYYYY(
                                        Claims?.Date__c
                                      );
                                      break;
                                  }
                                  let transactionDate = Claims?.CreatedDate?.split("T")

                                  return (
                                    <tr
                                      className="ladder_heading_data"
                                      key={index}
                                    >
                                      {/* <td>{Claims.sfId}</td> */}
                                      {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                      <td>
                                        <label class="custom-checkbox">
                                          <input type="checkbox" onChange={() => handleCheckboxChange(Claims.Id)} />
                                          <span class="checkmark"></span>
                                        </label>
                                      </td>
                                      <td>{claimDate}</td>
                                      <td>{formatDateStringDDMMYYYY(transactionDate?.[0])}</td>
                                      <td>{Claims.Contact__r?.EmpId__c}</td>
                                      <td>{Claims?.Contact__r?.Name}</td>
                                      <td>{Claims?.Contact__r?.Division__c}</td>
                                      <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                      <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                      <td>{Claims?.Distance_Travelled_Km__c}</td>
                                      <td>{Claims?.Allowance_Type__c}</td>
                                      <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                      <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                      <td>
                                        {/* {Claims.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims.url
                                              )
                                            }
                                          > */}
                                        {Claims.hasAttachments__c ?
                                          <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            <CommonButton
                                              title={"View Attachment"}
                                              onClick={() => handleViewAttachment(Claims.Id)}
                                            />
                                          </span> :
                                          <p>-</p>
                                        }
                                        {/* {Claims.hasAttachments__c &&
                                          <button onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            {String(Claims.hasAttachments__c)}
                                          </button>
                                        } */}
                                        {/* s */}
                                      </td>

                                      <td>
                                        {" "}
                                        {(Claims?.Status__c === "Saved" ||
                                          Claims?.Status__c ===
                                          "Pending For Approval" ||
                                          Claims?.Status__c ===
                                          "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {Claims?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {Claims?.Status__c}
                                      </td>
                                      {(Claims?.Status__c ===
                                        "Pending For Approval" || Claims.Status__c === "Pending for Release") && (
                                          <td className="reject-commomn-button">
                                            <input
                                              className="CommonDropdown form-select"
                                              type="text"
                                              style={{
                                                width: "100%",
                                                color: "rgb(46, 49, 146)",
                                                fontSize: "14px",
                                                backgroundImage: "none",
                                                paddingRight: "14px",

                                              }}
                                              placeholder="Enter Claim Remark"
                                              onChange={(e) => handleInputChange(Claims.Id, e.target.value)}
                                            />
                                          </td>
                                        )}
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Approved"
              title={approvedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {claims?.filter(data => data.status === "Approved")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {claims?.filter((data) => data.Status__c === "Approved" || data.Status__c === "Released" || data.Status__c === "Release")?.length >
                    0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Monthly Claim Status
                        </h4>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >
                          <table
                            className="w-100"
                            style={{ textAlign: "center" }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                {/* <th>Claim Id</th> */}
                                <th className="custom-header">Expense Date</th>
                                <th className="custom-header">
                                  Transaction Date
                                </th>
                                <th className="custom-header">Employee Id</th>
                                <th className="custom-header">Employee Name</th>
                                <th className="custom-header">Department</th>
                                <th className="custom-header">From Place</th>
                                <th className="custom-header">To Place</th>
                                <th className="custom-header">Distance (km)</th>
                                <th className="custom-header">Claim Type</th>
                                <th className="custom-header">
                                  Claim Sub Type
                                </th>
                                <th className="custom-header">Amount</th>
                                <th className="custom-header">
                                  View Attachment
                                </th>
                                <th className="custom-header">Status</th>
                                {/* <th className="custom-header">Remark</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {claims?.map((Claims, index) => {
                                if (Claims.Status__c === "Approved" || Claims.Status__c === "Released") {
                                  let claimDate;
                                  let fromDate;
                                  let toDate;
                                  switch (Claims.Allowance_Type__c) {
                                    case "Local Conveyance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Travel Allowance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Daily Allowance":
                                      if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                      }
                                      else {
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.DADate__c
                                        );
                                      }
                                      break;

                                    default:
                                      claimDate = formatDateStringDDMMYYYY(
                                        Claims?.Date__c
                                      );
                                      break;
                                  }
                                  let transactionDate = Claims?.CreatedDate?.split("T")
                                  return (
                                    <tr
                                      className="ladder_heading_data"
                                      key={index}
                                    >
                                      {/* <td>{Claims.sfId}</td> */}
                                      {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                      <td>{claimDate}</td>
                                      <td>
                                        {formatDateStringDDMMYYYY(
                                          transactionDate?.[0]
                                        )}
                                      </td>
                                      <td>{Claims.Contact__r?.EmpId__c}</td>
                                      <td>{Claims.Contact__r?.Name}</td>
                                      <td>{Claims.Contact__r?.Division__c}</td>
                                      <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                      <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                      <td>{Claims?.Distance_Travelled_Km__c}</td>
                                      <td>{Claims?.Allowance_Type__c}</td>
                                      <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                      <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                      <td>
                                        {/* {Claims?.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims?.url
                                              )
                                            }
                                          >
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span>
                                        )} */}
                                        {Claims.hasAttachments__c ?
                                          <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            <CommonButton
                                              title={"View Attachment"}
                                              onClick={() => handleViewAttachment(Claims.Id)}
                                            />
                                          </span> :
                                          <p>-</p>
                                        }
                                      </td>

                                      <td>
                                        {" "}
                                        {(Claims?.Status__c === "Saved" ||
                                          Claims?.Status__c ===
                                          "Pending For Approval" ||
                                          Claims?.Status__c ===
                                          "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {Claims?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {Claims?.Status__c}
                                      </td>
                                      {/* <td>{Claims?.rejectionReason}</td> */}
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Deleted"
              title={rejectedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {/* {claims?.filter(data => data.status === "Rejected")?.length === 0 && <p className="mt-5" style={{ fontWeight: 'bold' }}>No records to display</p>} */}
                  {claims?.filter((data) => data.Status__c === "Rejected")?.length >
                    0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Monthly Claim Status
                        </h4>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >
                          <table
                            className="w-100"
                            style={{ textAlign: "center" }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                {/* <th>Claim Id</th> */}
                                <th className="custom-header">Expense Date</th>
                                <th className="custom-header">
                                  Transaction Date
                                </th>
                                <th className="custom-header">Employee Id</th>
                                <th className="custom-header">Employee Name</th>
                                <th className="custom-header">Department</th>
                                <th className="custom-header">From Place</th>
                                <th className="custom-header">To Place</th>
                                <th className="custom-header">Distance (km)</th>
                                <th className="custom-header">Claim Type</th>
                                <th className="custom-header">
                                  Claim Sub Type
                                </th>
                                <th className="custom-header">Amount</th>
                                <th className="custom-header">
                                  View Attachment
                                </th>
                                <th className="custom-header">Status</th>
                                <th className="custom-header">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              {claims?.map((Claims, index) => {
                                if (Claims.Status__c === "Rejected") {
                                  let claimDate;
                                  let fromDate;
                                  let toDate;
                                  switch (Claims.Allowance_Type__c) {
                                    case "Local Conveyance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Travel Allowance":
                                      fromDate = formatDateStringDDMMYYYY(Claims?.TA_Arrival_Date__c);
                                      toDate = formatDateStringDDMMYYYY(Claims?.TA_Depature_Date__c);
                                      claimDate = `${fromDate} To ${toDate}`;
                                      break;

                                    case "Daily Allowance":
                                      if (Claims?.From_Date__c && Claims?.To_Date__c) {
                                        fromDate = formatDateStringDDMMYYYY(Claims?.From_Date__c);
                                        toDate = formatDateStringDDMMYYYY(Claims?.To_Date__c);
                                        claimDate = `${fromDate} To ${toDate}`;
                                      }
                                      else {
                                        claimDate = formatDateStringDDMMYYYY(
                                          Claims?.DADate__c
                                        );
                                      }
                                      break;

                                    default:
                                      claimDate = formatDateStringDDMMYYYY(
                                        Claims?.Date__c
                                      );
                                      break;
                                  }
                                  let transactionDate = Claims?.CreatedDate?.split("T")
                                  return (
                                    <tr
                                      className="ladder_heading_data"
                                      key={index}
                                    >
                                      {/* <td>{Claims.sfId}</td> */}
                                      {/* <td>{Claims.claimDate !== undefined && Claims.claimDate !== 'undefined' ? Claims.claimDate : ''}</td> */}
                                      <td>{claimDate}</td>
                                      <td>
                                        {formatDateStringDDMMYYYY(
                                          transactionDate?.[0]
                                        )}
                                      </td>
                                      <td>{Claims.Contact__r?.EmpId__c}</td>
                                      <td>{Claims.Contact__r?.Name}</td>
                                      <td>{Claims.Contact__r?.Division__c}</td>
                                      <td>{Claims?.Place_From__c || Claims?.TA_Depature_Station__c}</td>
                                      <td>{Claims?.Place_To__c || Claims?.TA_Arrival_Station__c || Claims?.City__c}</td>
                                      <td>{Claims?.Distance_Travelled_Km__c}</td>
                                      <td>{Claims?.Allowance_Type__c}</td>
                                      <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                                      <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                                      <td>
                                        {/* {Claims?.url?.length > 0 && (
                                          <span
                                            onClick={() =>
                                              handleApproveClick(
                                                Claims?.url
                                              )
                                            }
                                          >
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span>
                                        )} */}
                                        {Claims.hasAttachments__c ?
                                          <span onClick={() => handleViewAttachment(Claims.Id, Claims.url)}>
                                            <CommonButton
                                              title={"View Attachment"}
                                              onClick={() => handleViewAttachment(Claims.Id)}
                                            />
                                          </span> :
                                          <p>-</p>
                                        }
                                      </td>

                                      <td>
                                        {" "}
                                        {(Claims?.Status__c === "Saved" ||
                                          Claims?.Status__c ===
                                          "Pending For Approval" ||
                                          Claims?.Status__c ===
                                          "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {Claims?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(Claims?.Status__c === "Approved" || Claims?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {Claims?.Status__c}
                                      </td>
                                      <td>{Claims?.rejectionReason}</td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

        {/* 
        <Card className="monthlyClaim_mobile">
          <Card.Body>
            <h4
              className="mb-4"
              style={{ textAlign: "center" }}
            >
              Monthly Claim Status
            </h4>
            <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
              <table className="w-100" style={{ textAlign: "center" }} ref={tableRef}>
                <thead>
                  <tr className="ladder_heading">
                    <th className="custom-header">Expense Date</th>
                    <th className="custom-header">Transaction Date</th>
                    <th className="custom-header">Employee Id</th>
                    <th className="custom-header">Employee Name</th>
                    <th className="custom-header">Department</th>
                    <th className="custom-header">Claim Type</th>
                    <th className="custom-header">Claim Sub Type</th>
                    <th className="custom-header">Amount</th>
                    <th className="custom-header">View Attachment</th>
                    <th className="custom-header">Status</th>
                    <th className="custom-header">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {claims?.map((Claims, index) => {
                    let claimDate = Claims.claimDate;
                    if (claimDate && claimDate.includes(' to ')) {
                      let [fromDate, toDate] = claimDate.split(' to ');
                      if (fromDate && toDate) {
                        const formatDate = (date) => {
                          const [year, month, day] = date.split('-');
                          const months = [
                            "January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"
                          ];
                          return `${day} ${months[parseInt(month) - 1]} ${year}`;
                        };

                        switch (Claims.claimType) {
                          case "Local Conveyance":
                          case "Daily Allowance":
                          case "Travel Allowance":
                            fromDate = formatDate(fromDate);
                            toDate = formatDate(toDate);
                            claimDate = `${fromDate} To ${toDate}`;
                            break;

                          default:
                            claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                            break;
                        }
                      } else {
                        claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                      }
                    } else {
                      claimDate = formatDateStringDDMMYYYY(Claims?.claimDate);
                    }
                    return (
                      <tr className="ladder_heading_data" key={index}>
                        <td>{claimDate}</td>
                        <td>{formatDateStringDDMMYYYY(TransactionDate?.[0])}</td>
                        <td>{Claims.Contact__r?.EmpId__c}</td>
                        <td>{Claims.Contact__r?.Name}</td>
                        <td>{Claims.Contact__r?.Division__c}</td>
                        <td>{Claims?.Allowance_Type__c}</td>
                        <td>{Claims.Daily_Allowance_Type__c || Claims?.Local_Conveyance_Type__c || Claims?.Travel_allowance_Type__c || Claims?.Type_of_General_Expense__c}</td>
                        <td>{Claims?.Daily_Allowance__c || Claims?.GE_Claim_Amount__c || Claims?.TA_Claim_Amount__c}</td>
                        <td>
                          {Claims.attachmentUrls?.length > 0 && (
                            <span onClick={() => handleApproveClick(Claims.attachmentUrls[0].Body, Claims.attachmentUrls[0].ContentType)}>
                              <CommonButton title={"View Attachment"} />
                            </span>
                          )}
                        </td>

                        <td>
                          {" "}
                          {(Claims?.status === "Saved" ||
                            Claims?.status === "Pending For Approval") && (
                              <img className="MTP_approved" src={question} alt="" />
                            )}
                          {Claims?.status === "Rejected" && (
                            <img className="MTP_approved" src={cross} alt="" />
                          )}
                          {Claims?.status === "Approved" && (
                            <img className="MTP_approved" src={check} alt="" />
                          )}
                          {Claims?.status}
                        </td>
                        {Claims?.status === "Pending For Approval" && (
                          <td>
                            <span
                              onClick={() => {
                                handleClaimAction(Claims.sfId, "Approved");
                              }}
                            >
                              <CommonButton title={"Approve"} />
                            </span>
                            <span
                              onClick={() => {
                                handleshowRejecteModal(Claims.sfId, "Rejected")
                              }}
                              style={{ paddingLeft: "10px" }}
                            >
                              <CommonButton title={"Reject"} />
                            </span>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card> */}
      </div>
      <Modal
        show={showApproveModal}
        onHide={handleshowApproveModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>View Attachment</h5>
          {(contentTypes === "png" ||
            contentTypes === "jpg" ||
            contentTypes === "jpeg") && (
              <span onClick={() => downloadImage(attachmentUrls, "jpeg")}>
                <DownloadForOfflineIcon
                  style={{
                    fontSize: 30,
                    color: "#00a87e",
                    float: "right",
                    marginLeft: "1rem",
                  }}
                />
              </span>
            )}
          {contentTypes === "pdf" && (
            <span onClick={handleDownloadPdf}>
              <DownloadForOfflineIcon
                style={{
                  fontSize: 30,
                  color: "#00a87e",
                  float: "right",
                  marginLeft: "1rem",
                }}
              />
            </span>
          )}
          {/* <span onClick={() => downloadImage(attachmentUrls)}>
            <DownloadForOfflineIcon style={{ fontSize: 30, color: '#00a87e', float: 'right', marginLeft: '1rem' }} />
          </span> */}
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row text-center">
            {/* <img src={attachmentUrls} alt="Attachment" className="img-fluid" /> */}
            {(contentTypes === "png" ||
              contentTypes === "jpg" ||
              contentTypes === "jpeg") && (
                <img
                  src={`data:image/jpeg;base64,${attachmentUrls}`}
                  alt="Attachment"
                  className="img-fluid"
                />
              )}
            {contentTypes === "pdf" && (
              // <embed src={`data:application/pdf;base64,${attachmentUrls}`} type="application/pdf" width="100%"/>
              <Document file={`data:application/pdf;base64,${attachmentUrls}`}>
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="" onClick={handleshowApproveModal}>
              <CommonButton
                title={"Ok"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={rejectModal}
        onHide={handleshowRejecteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>Enter Reason For Rejection</h5>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row">
            <p className="PlanMTP_leftHead">Enter the reason</p>
            <div className="container">
              <input
                type="text"
                className="CommonDropdown form-select"
                onChange={handleRejectReasonChange}
                style={{
                  width: "100%",
                  color: "rgb(46, 49, 146)",
                  fontSize: "14px",
                  backgroundImage: "none",
                  paddingRight: "14px",
                }}
              />
              {rejectionReason && (
                <p style={{ color: "red" }}>
                  Please enter a reason for rejection.
                </p>
              )}
            </div>
            {/* <input type="text" placeholder="Enter the reason" onChange={handleRejectReasonChange} /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="col-6" style={{ marginLeft: "-5.5px" }}>
              <span
                onClick={() => {
                  handleClaimAction(rejectId, "Rejected", selectedClaim);
                }}
              >
                <CommonButton title={"Yes"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowRejecteModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};
