/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import "./UploadRoutes.css";
import CommonButton from '../Common/CommonButton';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Common/Loader';
import BackButton from "../Common/BackButton";
import upload from "../assets/images/file.png"
import { createCustomersApi } from '../services/createRout/createCustomersApi';


const UploadCustomer = () => {
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState();
    const [fileError, setFileError] = useState('');

    const [formData, setFormData] = useState({
        Division: '',
        HQ: '',
        Route: '',
        RouteDescription: '',
        StartingPoint: '',
        MidPoint: '',
        EndPoint: '',
        TotalDistanceCovered: '',
        TypeofStation: '',
        empCode: '',
        Customer: '',
        Category: '',
        Population_of_Animals: '',
        Visit_Frequency: '',
        Retailer_c: '',
        Hester_Business: '',
        Total_Potential: '',
        DOB: '',
        Focus_Product_1: '',
        Focus_Product_2: '',
        Focus_Product_3: '',
        Therapeutic_Segments: '',
        Customer_Type: '',
        ADDRESS: '',
        Country: '',
        State: '',
        City: '',
        PINcode: '',
        Area: '',
        ContactPerson: '',
        Designation: '',
        EmailID: '',
        WatsappNO: '',
        MobileNumber2: '',
        Remarks: '',
        ISSecCustomer: ''
    });

    const fieldLabels = {
        Division: 'Division',
        HQ: 'HQ',
        Route: 'Route',
        RouteDescription: 'Route Description',
        StartingPoint: 'Starting Point',
        MidPoint: 'Mid Point',
        EndPoint: 'End Point',
        TotalDistanceCovered: 'Total Distance Covered',
        TypeofStation: 'Type of Station',
        empCode: 'Employee Code',
        Customer: 'Customer',
        Category: 'Category',
        Population_of_Animals: 'Population of Animals',
        Visit_Frequency: 'Visit Frequency',
        Retailer_c: 'Retailer',
        Hester_Business: 'Hester Business',
        Total_Potential: 'Total Potential',
        DOB: 'Date of Birth',
        Focus_Product_1: 'Focus Product 1',
        Focus_Product_2: 'Focus Product 2',
        Focus_Product_3: 'Focus Product 3',
        Therapeutic_Segments: 'Therapeutic Segments',
        Customer_Type: 'Customer Type',
        ADDRESS: 'Address',
        Country: 'Country',
        State: 'State',
        City: 'City',
        PINcode: 'PIN code',
        Area: 'Area',
        ContactPerson: 'Contact Person',
        Designation: 'Designation',
        EmailID: 'Email ID',
        WatsappNO: 'WhatsApp Number',
        MobileNumber2: 'Mobile Number 2',
        Remarks: 'Remarks',
        ISSecCustomer: 'Is Sec Customer'
    };

    const accessToken = useSelector((state) => state?.accessToken?.accessToken);

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        setFileName(file.name);
        setFileError('');  // Clear the file error when a file is selected

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                if (binaryStr) {
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet);

                    const formattedData = {
                        Customer: data.map(item => ({
                            Route: item['Route'] || '',
                            Customer: item['Customer'] || '',
                            Category: item['Category'] || '',
                            Population_of_Animals: item['Population of Animals (Only for Dairy Farmer)'] || '',
                            Visit_Frequency: String(item['Visit Frequency']) || '',
                            Retailer_c: item['Retailer c'] || '',
                            Hester_Business: item['Hester Business'] || '',
                            Total_Potential: item['Total Potential'] || '',
                            DOB: item['DOB'] || '',
                            Focus_Product_1: item['Focus Product 1'] || '',
                            Focus_Product_2: item['Focus Product 2'] || '',
                            Focus_Product_3: item['Focus Product 3'] || '',
                            Therapeutic_Segments: item['Therapeutic Segments'] || '',
                            Customer_Type: item['Customer Type'] || '',
                            ADDRESS: item['ADDRESS'] || '',
                            Country: item['Country'] || '',
                            State: item['State'] || '',
                            City: item['City'] || '',
                            PINcode: String(item['PINcode']) || '',
                            Area: item['Area'] || '',
                            ContactPerson: item['ContactPerson'] || '',
                            Designation: item['Designation'] || '',
                            EmailID: item['EmailID'] || '',
                            WatsappNO: item['WatsappNO'] || '',
                            MobileNumber2: String(item['MobileNumber2']) || '',
                            Remarks: item['Remarks (if any)'] || '',
                            Division: item['Division'] || '',
                            ISSecCustomer: String(item['ISSecCustomer']) || ''
                        }))
                    };

                    setJsonData(formattedData);
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async () => {
        if (!fileName) {  // Check if no file is selected
            setFileError("Please select a file before submitting");
            return;  // Prevent submission if no file is selected
        }

        setLoading(true);
        console.log("data", jsonData);

        const response = await createCustomersApi(accessToken, jsonData);

        console.log("response", response);
        if (response) {
            toast.success("Data uploaded successfully");
        } else {
            toast.error("An error occurred");
        }

        setLoading(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Allow only numeric input for specific fields
        if (name === 'TotalDistanceCovered' || name === 'empCode' || name === 'PINcode' || name === 'WatsappNO' || name === 'MobileNumber2' || name === 'Population_of_Animals' || name === 'Visit_Frequency') {
            if (/^\d*$/.test(value)) { // Check if value is numeric
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = `${fieldLabels[key]} is required`;
            }
        });
        return newErrors;
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Transform form data into the required payload format
        const payload = {
            Customer: [formData]
        };

        setLoading(true);
        console.log('Form Data Submitted:', payload);

        try {
            const response = await createCustomersApi(accessToken, payload);
            console.log("response", response);

            // Check if response has the correct format
            if (response && response.customers && response.customers.length > 0) {
                const customerResponse = response.customers[0]; // Get the first customer's response
                if (customerResponse.status === 'success') {
                    toast.success(customerResponse.message || 'Data submitted successfully');
                } else if (customerResponse.status === 'error') {
                    toast.error(customerResponse.message || 'An error occurred during submission');
                }
            } else {
                toast.error('Unexpected response format.');
            }

        } catch (error) {
            toast.error('An error occurred while submitting the form.');
        }

        // Clear form after submission
        setFormData({
            Division: '',
            HQ: '',
            Route: '',
            RouteDescription: '',
            StartingPoint: '',
            MidPoint: '',
            EndPoint: '',
            TotalDistanceCovered: '',
            TypeofStation: '',
            empCode: '',
            Customer: '',
            Category: '',
            Population_of_Animals: '',
            Visit_Frequency: '',
            Retailer_c: '',
            Hester_Business: '',
            Total_Potential: '',
            DOB: '',
            Focus_Product_1: '',
            Focus_Product_2: '',
            Focus_Product_3: '',
            Therapeutic_Segments: '',
            Customer_Type: '',
            ADDRESS: '',
            Country: '',
            State: '',
            City: '',
            PINcode: '',
            Area: '',
            ContactPerson: '',
            Designation: '',
            EmailID: '',
            WatsappNO: '',
            MobileNumber2: '',
            Remarks: '',
            ISSecCustomer: ''
        });

        setErrors({});
        setLoading(false);
    };


    const handlePhotoIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <div className="container-fluid mt-2">
                <div className="row mt-3 mb-4">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mb-3">
                        <BackButton title={"Upload Customers"} />
                    </div>
                </div>
                <div className="markAttendance Approve_plansTab_heading">
                    <Tabs
                        defaultActiveKey="Pending"
                        id="fill-tab-example"
                        className="my-3 markAttendance_tab Approve_plansTab"
                        fill
                        style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
                    >
                        <Tab
                            eventKey="Pending"
                            title={"Multiple"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="" style={{ textAlign: "center" }}>
                                        Upload Excel File
                                    </h4>
                                    <div className="markAttendance_section container-fluid">
                                        <img alt="upload image" src={upload} style={{ height: '3rem' }} onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" />

                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileUpload}
                                        />
                                        {fileError && <p className="error-message" style={{ color: 'red', textAlign: "center" }}>{fileError}</p>}
                                    </div>
                                    <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                                    <span className="leaveApply_button" onClick={handleSubmit}>
                                        <CommonButton title={"Submit file"} width={'18rem'} />
                                    </span>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab
                            eventKey="Deleted"
                            title={"Single"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="mb-4" style={{ textAlign: "center" }}>
                                        Upload Route
                                    </h4>
                                    <div className="formContainer">
                                        <form onSubmit={formSubmit}>
                                            <div className="form-row">
                                                {Object.keys(formData).map((field, index) => (
                                                    <div className="form-group" key={field}>
                                                        <p className="PlanMTP_leftHead" htmlFor={field} style={{ textAlign: 'start' }}>
                                                            {fieldLabels[field]}
                                                        </p>
                                                        <input
                                                            className="CommonDropdown form-select"
                                                            type="text"
                                                            style={{
                                                                width: "100%",
                                                                color: "rgb(46, 49, 146)",
                                                                fontSize: "14px",
                                                                backgroundImage: "none",
                                                                paddingRight: "14px",

                                                            }}
                                                            id={field}
                                                            name={field}
                                                            value={formData[field]}
                                                            onChange={handleInputChange}

                                                        />
                                                        {errors[field] && <p className="error-message">{errors[field]}</p>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ marginTop: '1rem' }}>
                                                <span className="leaveApply_button" onClick={formSubmit}>
                                                    <CommonButton title={"Submit"} width={'18rem'} value="Submit" />
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loader />}
        </div>
    );
};

export default UploadCustomer;
