import Card from "react-bootstrap/Card";
import "../Attendance/TodaysPlan.css";
import { useEffect, useState } from "react";
import locationIcon from "./../../assets/images/location-59.svg";
import profileIcon from "./../../assets/images/user-3297.svg";
import mobileIcon from "./../../assets/images/mobile-phone-2640.svg";
import clockIcon from "./../../assets/images/circular-wall-clock-black-outline-17714.svg";
import { createNTPApi } from "../../services/NTP/createNTPApi";
import { useSelector } from "react-redux";
import CommonButton from "../../Common/CommonButton";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Common/Loader";
import { deleteCustomerFromTP } from "../../services/NTP/deleteCustomerFromTP";
import FailButton from "./../../Common/FailButton";
import {
  useNavigate,
} from "react-router-dom";
import { editNTPApi } from "../../services/NTP/editNTPApi";
import doctorIcon from "../../assets/images/stethoscope-3539.svg";

const RouteWiseData = ({
  doctors,
  showCheckbox = false,
  date,
  station,
  tour,
  routeName,
  pob,
  editedCustomerIds,
  editedCustomersData,
  TpEditDataFetch,
  editTour,
  tourSrno,routeAllDesc,
  hq,
  vseName
}) => {
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const UserId = useSelector((state) => state?.userDetails?.details?.Id);
  const ReportToId = useSelector(
    (state) => state?.userDetails?.details?.ReportsToId
  );
  const navigate = useNavigate();
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTPcustomerId, setDeleteTPcustomerId] = useState("");
  const [splitedPob, setSplitedPob] = useState("");

  useEffect(() => {
    const doctorsInfo = doctors.filter((doctor) =>
      editedCustomerIds.includes(doctor.Id)
    );

    setSelectedDoctors(doctorsInfo);
  }, [doctors, editedCustomerIds]);

  const handleCheckboxChange = (doctor) => {
    const index = selectedDoctors.findIndex(
      (selectedDoctor) => selectedDoctor.Id === doctor.Id
    );
    if (index === -1) {
      // If doctor is not already selected, add to selectedDoctors
      setSelectedDoctors([...selectedDoctors, doctor]);
    } else {
      // If doctor is already selected, remove it from selectedDoctors
      const updatedDoctors = [...selectedDoctors];
      updatedDoctors.splice(index, 1);
      setSelectedDoctors(updatedDoctors);
    }
  };

  const doctorIdKeyValuePairs = selectedDoctors?.map(({ Id }) => ({
    doctorId: Id,
  }));

  const handleSubmit = () => {
    const place = "";
    if (date !== "" && station !== "" && selectedDoctors.length !== 0) {
      setLoading(true);
      (async () => {
        const response = await createNTPApi(
          UserId,
          date,
          station,
          tour,
          routeName,
          pob,
          splitedPob,
          ReportToId,
          doctorIdKeyValuePairs,
          place,
          accessToken,
          routeAllDesc,
          vseName,  
          hq
        );
        if (response?.status === 200) {
          setLoading(false);
          if (response?.data?.trim() === "Tour plans created successfully") {
            toast.success("Tour plan saved successfully");
            navigate(-1)
          }
          else if (response?.data?.trim() === "Tour plan already exist for same date, please edit or create new tour plan.") {
            setLoading(false);
            toast.error("Tour plan already exist for same date, please edit or create new tour plan.")
          }
          else {
            setLoading(false);
            toast.error("Unable to create tour plan");
          }
        }
        else {
          setLoading(false);
          toast.error("Unable to create tour plan");
        }
      })();
    }
    else {
      toast.error("Please select date, station And doctors")
    }
  };

  const handleEdit = () => {
    const place = "";
    if (date !== "" && station !== "" && selectedDoctors.length !== 0) {
      setLoading(true);
      (async () => {
        const response = await editNTPApi(
          UserId,
          date,
          station,
          tour,
          routeName,
          ReportToId,
          doctorIdKeyValuePairs,
          place,
          accessToken,
          tourSrno,
          splitedPob
        );
        if (response?.status === 200) {
          setLoading(false);
          toast.success("Tour plan edited successfully");
          navigate(-1)
        } else {
          setLoading(false);
          toast.error("Unable to edit tour plan");
        }
      })();
    }
    else {
      toast.error("Please select date, station And doctors")
    }
  };

  // console.log("edited customers---",editedCustomersData)

  const handleDeleteClick = (idToDelete) => {
    const selectedId = editedCustomersData?.find(
      (route) => route?.doctorId === idToDelete
    );
    setDeleteTPcustomerId(selectedId?.TPId);
    setShowDeleteModal(true);
    // console.log("delete id---",selectedId?.TPId)
  };

  const handleshowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    setLoading(true);
    const response = await deleteCustomerFromTP(
      deleteTPcustomerId,
      accessToken
    );
    if (response?.status === 204) {
      toast.success("Removed successfully!");
      setShowDeleteModal(false);
      TpEditDataFetch();
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("Removed unsuccessfully!");
    }
  };

  useEffect(() => {
    let doctorCount = selectedDoctors.length;
    let dividedPob = pob / doctorCount
    setSplitedPob(dividedPob)
  }, [selectedDoctors,pob]);

  return (
    <>
      {tour === "Field Work" && routeName ? (
        <>
          {doctors?.map((doctor, index) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={index}>
                <Card className="mt-4 DoctorCard">
                  <Card.Body>
                    <div className="DoctorPharm_Name">
                      <span className="DoctorPharm_NameTitle">
                        <input
                          type="checkbox"
                          name={doctor?.Id}
                          id=""
                          className="mr-1 MTP_checkbox"
                          onChange={() => handleCheckboxChange(doctor)}
                          checked={selectedDoctors.some(
                            (selectedDoctor) => selectedDoctor?.Id === doctor.Id
                          )}
                        />

                        {doctor.Name}
                      </span>
                      {editedCustomerIds.some(
                        (editedDoctor) => editedDoctor === doctor.Id
                      ) &&
                        <ClearIcon className="MTP_cross"
                          onClick={() => handleDeleteClick(doctor?.Id)}
                        />}
                      {/* <span className="DoctorPharm_badge">
                      {doctor.DocPharmtype}
                    </span> */}
                    </div>
                    <div
                      className="DoctorPharm_Name"
                      style={{ marginTop: "5px" }}
                    >
                      <span className="DoctorPharm_lastVisit">
                        <img
                          src={clockIcon}
                          alt="clock"
                          style={{
                            height: "1rem",
                            marginRight: "5px",
                            marginBottom: "2px",
                          }}
                        />
                        <b>DOB: </b>
                        {doctor.DOB__c}
                      </span>
                      {/* <span className="DoctorPharm_badge2">{doctor.visitNo}</span> */}
                    </div>
                    <div className="DoctorPharm_Post">
                      <span className="mr-3">
                        <img
                          src={profileIcon}
                          alt="profile"
                          style={{
                            height: "1rem",
                            marginRight: "3px",
                            marginBottom: "2px",
                          }}
                        />
                        {doctor.ContactPerson__c}
                      </span>
                      <span>
                        <img
                          src={mobileIcon}
                          alt="mobile"
                          style={{
                            height: "1rem",
                            marginRight: "3px",
                            marginBottom: "2px",
                          }}
                        />
                        {doctor.MobileNumber__c}
                      </span>
                    </div>
                    <div className="DoctorPharm_Post">
                      <img
                        src={doctorIcon}
                        alt="location"
                        style={{
                          height: "1rem",
                          marginRight: "3px",
                          marginBottom: "2px",
                        }}
                      />
                      <span>Category: {doctor?.Category__c}</span>
                    </div>
                    <div className="DoctorPharm_Post">
                      <img
                        src={locationIcon}
                        alt="location"
                        style={{
                          height: "1rem",
                          marginRight: "3px",
                          marginBottom: "2px",
                        }}
                      />
                      {doctor.Address__c},
                      <br />
                      <span>
                        {doctor.Area__c},{doctor.City__c}
                      </span>
                      <br />
                      <span>
                        {doctor.Pincode__c},{doctor.Country__c}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
          <Modal
            show={showDeleteModal}
            onHide={handleshowDeleteModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="doctor360Modal_survey"
          >

            <Modal.Body className="show-grid">
              <div className="row text-center">
                <div className="row mb-5">
                  <h5>Remove Customer</h5>
                  <h6>
                    Remove customer from this tour.
                  </h6>
                </div>
                <div className="row w-100">
                  <div
                    className="col-6"
                    style={{ marginLeft: "-5.5px" }}
                    onClick={handleshowDeleteModal}
                  >
                    <span onClick={handleDelete}>
                      <CommonButton title={"Remove"} width={"100%"} />
                    </span>
                  </div>
                  <div className="col-6" onClick={handleshowDeleteModal}>
                    <FailButton
                      buttonText={"No"}
                      height={"2rem"}
                      width={"100%"}
                      variant="contained"
                      className="doctorName_button"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {editTour ?
            <div className="mt-4">
              <span onClick={handleEdit}>
                <CommonButton title={"Save Edited Tour"} width={"10rem"} />
              </span>
            </div>
            :
            <div className="mt-4">
              <span onClick={handleSubmit}>
                <CommonButton title={"Save"} width={"10rem"} />
              </span>
            </div>
          }
        </>
      ) : (
        " "
      )}
      <ToastContainer />
      {loading && <Loader />}
    </>
  );
};

export default RouteWiseData;
