export const GET_ACCESS_TOKEN_SUCCESS = "GET_ACCESS_TOKEN_SUCCESS";
export const GET_ACCESS_TOKEN_FAIL = "GET_ACCESS_TOKEN_FAIL";
export const GET_EMP_DETAILS_SUCCESS = "GET_EMP_DETAILS_SUCCESS";
export const GET_EMP_DETAILS_FAIL = "GET_EMP_DETAILS_FAIL";
export const BLANK_RECORD_ID = "BLANK_RECORD_ID";
export const BLANK_RECORD_ID_FAIL = "BLANK_RECORD_ID_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE';
export const FAIL_PROFILE_IMAGE = 'FAIL_PROFILE_IMAGE';
export const ACTUAL_BUDGET_API_SUCCESS = 'ACTUAL_BUDGET_API_SUCCESS'
export const ACTUAL_BUDGET_API_FAIL = 'ACTUAL_BUDGET_API_FAIL'
export const LOG_IN_TIME_SUCCESS = 'LOG_IN_TIME_SUCCESS'
export const LOG_IN_TIME_FAIL = 'LOG_IN_TIME_FAIL'
export const LEAVEDETAILS_SUCCESS = 'LEAVEDETAILS_SUCCESS'
export const LEAVEDETAILS_FAIL = 'LEAVEDETAILS_FAIL'
export const FESTIVALHOLIDAY_FAIL = 'LEAVEDETAILS_FAIL'
export const FESTIVALHOLIDAY_SUCCESS = 'FESTIVALHOLIDAY_SUCCESS'


// export const REGULARIZATION_ID_FAIL = "REGULARIZATION_ID_FAIL";


