import axios from "axios";
import { configs } from "../config";


export const sendRegularizationApi = async (empId,accessToken, regularizationDate, login, logout,regId) => {
  const options = {
    url: `${configs?.base_url}/services/data/v58.0/sobjects/Attendence__c/${regId}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      EmpId__c: empId,
      // Date1__c: regularizationDate,
      Log_In__c: login,
      Log_Out__c: logout,
      isRegularized__c: true
    },
  };
  try {
    const response = await axios(options);
    return response;
  } catch (error) {
    console.log(error);
  }
};
