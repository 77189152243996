import React, { useState, forwardRef } from "react";
import CommonButton from "../../Common/CommonButton";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { reportDownloadApi } from "../../services/reports/reportDownloadApi";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { ntpReportDownloadApi } from "../../services/reports/ntpReportApi";
import "../../Components/Expense/ExpenseClaim.css"
import { toast, ToastContainer } from "react-toastify";

const TpReport = forwardRef((props, ref) => {
  const [allTp, setAllTp] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterEmpId, setFilterEmpId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const division = useSelector((state) => state?.userDetails?.details?.Division__c);

  const fetchData = async () => {
    setLoading(true); // Start loading state

    try {
      // Fetch data from API
      const response = await ntpReportDownloadApi(
        accessToken,
        filterEmpId,
        startDate,
        endDate,
        designation,
        empId,
        '4000',
        division
      );

      // Log the entire response to understand its structure
      console.log("API Response:", response?.error?.response?.status);

      // Check if the response is an error response
      if (response?.error?.response) {
        const statusCode = response.error.response.status;
        console.log("API Error Response:", statusCode);

        // If the status is 400, show a toast message
        if (statusCode === 400) {
          toast.error("Data not found");
          setAllTp([]); // Reset data to an empty array
          setDataFetched(false); // Indicate no data was fetched
          return; // Exit the function early
        }

        // Handle other status codes or cases as needed
        throw new Error(`Error: ${statusCode}`);
      }

      // Check if response and response.data.records are defined and have records
      if (!response || !response.data || !Array.isArray(response.data.records)) {
        console.error("Unexpected response format:", response);
        setAllTp([]); // Reset data to an empty array
        setDataFetched(false); // Indicate no data was fetched
        throw new Error("Invalid response format");
      }

      // Access the array of tour plans
      const dataArray = response.data.records;
      console.log("Fetched Data Array:", dataArray);

      // If dataArray is empty, clear the state and exit
      if (dataArray.length === 0) {
        setAllTp([]); // Reset data to an empty array
        setDataFetched(false); // Indicate no data was fetched
        return; // Exit the function early
      }

      // Set default dates if not provided
      const start = startDate ? new Date(startDate) : new Date("1900-01-01");
      const end = endDate ? new Date(endDate) : new Date("2100-01-01");
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);

      // Filter and sort data
      const filteredData = dataArray.filter((item) => {
        const itemDate = new Date(item?.Date__c);
        return (
          itemDate >= start &&
          itemDate <= end &&
          (!filterEmpId || filterEmpId === item?.Contact__r?.EmpId__c)
        );
      });

      console.log("Filtered Data:", filteredData);

      const sortedData = filteredData.map((item) => {
        return {
          ...item,
          FixedPOB__c: !item?.FixedPOB__c || isNaN(parseFloat(item.FixedPOB__c))
            ? '-'
            : parseFloat(item.FixedPOB__c),
        };
      });

      console.log("Sorted and Processed Data:", sortedData);

      const finalSortedData = sortedData.sort(
        (a, b) => new Date(b?.Date__c) - new Date(a?.Date__c)
      );

      console.log("Final Sorted Data:", finalSortedData);

      // Update state
      setAllTp(finalSortedData);
      setDataFetched(finalSortedData.length > 0); // Set dataFetched based on the presence of data

    } catch (error) {
      // Check if error response status is 400
      if (error?.response?.status === 400) {
        toast.error("Invalid Employee code");
        setAllTp([]); // Reset data to an empty array
      } else {
        console.error("Error fetching tour plans:", error);
        setAllTp([]); // Reset data to an empty array in case of other errors
      }
      setDataFetched(false); // Indicate no data was fetched in case of error
    } finally {
      setLoading(false); // End loading state
    }
  };



  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
      setError("Please enter only numbers.");
    } else {
      setError("");
    }
  };

  const handleSubmit = () => {
    fetchData();
  };

  const handleExport = () => {
    try {
      if (!allTp || allTp.length === 0) {
        alert("No data available to export");
        return;
      }

      // Convert data to worksheet
      const data = allTp.map((item) => ({
        Date: formatDateStringDDMMYYYY(item?.Date__c) || "N/A",
        "Emp Code": item?.Contact__r?.EmpId__c || "N/A",
        "Emp Name": item?.Contact__r?.Name || "N/A",
        HQ: item?.Contact__r?.HQ__c || "N/A",
        "Joint Work Emp Code":
          item?.Status__c === "Tour Plan Cloned"
            ? item?.Tour_Plan_Approval__r?.EmpId__c
            : "-",
        "Joint Work Emp Name":
          item?.Status__c === "Tour Plan Cloned"
            ? item?.Tour_Plan_Approval__r?.Name
            : "-",
        "Type of TP":
          item?.Type_of_Tour_Plan__c === "Field Work"
            ? item?.Type_of_Tour_Plan__c
            : "Other Type Of Tour Plan",
        "Type of Customer Activity":
          item?.Type_of_Tour_Plan__c === "Field Work"
            ? "-"
            : [
              "Customer Engagement Activity",
              "Seminar/Conference",
              "Admin Work",
            ].includes(item?.Type_of_Tour_Plan__c)
              ? item?.Type_of_Tour_Plan__c
              : "-",
        'Place': item?.Place__c,
        "Type of HQ": "-",
        "Route No.": item?.Route__c || "-",
        "Route Name": item?.Route_Name__c || "-",
        "Route POB": parseFloat(item?.FixedPOB__c) || "0.00",
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Tour Plan");

      XLSX.writeFile(workbook, "Tour_Plan_Report.xlsx");
    } catch (error) {
      console.error("Error generating Excel file:", error);
      alert(
        "Failed to generate Excel file. Please check the console for details."
      );
    }
  };

  return (
    <div className="markAttendance_section container-fluid">
      <div className="row mb-5">
        <Card className="monthlyClaim_mobile">
          <Card.Body>
            <h4 className="mb-4 text-center">Tour Plan Report</h4>
            <div className="filter-section mb-4">
              <label style={{ marginRight: "1rem" }}>
                Start Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  value={startDate}
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: "1rem" }}>
                End Date
                <input
                  className="CommonDropdown form-select"
                  type="date"
                  style={{
                    width: "100%",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </label>
              <label style={{ marginRight: "1rem" }}>
                EmpId
                <input
                  className="CommonDropdown form-select"
                  type="text"
                  max={4}
                  style={{
                    width: "8rem",
                    color: "rgb(46, 49, 146)",
                    fontSize: "14px",
                    backgroundImage: "none",
                    paddingRight: "14px",
                  }}
                  value={filterEmpId}
                  onChange={(e) => setFilterEmpId(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </label>
              <span onClick={handleSubmit}>
                <CommonButton title={"Submit"} className="btn btn-primary" />
              </span>
              {dataFetched && (
                <span style={{ marginLeft: "10px" }} onClick={handleExport}>
                  <CommonButton
                    title={"Download Excel"}
                    className="btn btn-primary"
                  />
                </span>
              )}
            </div>
            {error && <span className="dcr-form-errors">{error}</span>}
            {loading ? (
              <div>Loading...</div>
            ) : (
              dataFetched && (
                <div
                  id="table-to-xls"
                  className="table-wrapper-scroll-y custom-scrollbar"
                  style={{ padding: "20px", overflow: "auto" }}
                >
                  <table style={{ textAlign: "center", width: "150%" }} ref={ref}>
                    <thead>
                      <tr className="ladder_heading">
                        <th className="custom-header">Date</th>
                        <th className="custom-header">Emp Code</th>
                        <th className="custom-header">Emp Name</th>
                        <th className="custom-header">HQ</th>
                        <th className="custom-header">Joint Work Emp Code</th>
                        <th className="custom-header">Joint Work Emp Name</th>
                        <th className="custom-header">Type of TP</th>
                        <th className="custom-header">
                          Type of Customer Activity
                        </th>
                        <th className="custom-header">Place</th>
                        <th className="custom-header">Type of HQ</th>
                        <th className="custom-header">Route No.</th>
                        <th className="custom-header">Route Name</th>
                        <th className="custom-header">Route POB</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTp?.map((data, index) => (
                        <tr key={index} className="ladder_heading_data">
                          <td className="pl-3">
                            {formatDateStringDDMMYYYY(data?.Date__c)}
                          </td>
                          <td className="pl-3">{data?.Contact__r?.EmpId__c}</td>
                          <td className="pl-3">{data?.Contact__r?.Name}</td>
                          <td className="pl-3">{data?.Contact__r?.HQ__c}</td>
                          <td className="pl-3">
                            {data?.Status__c === "Tour Plan Cloned"
                              ? data?.Tour_Plan_Approval__r?.EmpId__c
                              : "-"}
                          </td>
                          <td className="pl-3">
                            {data?.Status__c === "Tour Plan Cloned"
                              ? data?.Tour_Plan_Approval__r?.Name
                              : "-"}
                          </td>
                          <td className="pl-3">
                            {data?.Type_of_Tour_Plan__c === "Field Work"
                              ? data?.Type_of_Tour_Plan__c
                              : "Other Type Of Tour Plan"}
                          </td>
                          <td className="pl-3">
                            {data?.Type_of_Tour_Plan__c === "Field Work"
                              ? "-"
                              : [
                                "Customer Engagement Activity",
                                "Seminar/Conference",
                                "Admin Work",
                              ].includes(data?.Type_of_Tour_Plan__c)
                                ? data?.Type_of_Tour_Plan__c
                                : "-"}
                          </td>
                          <td className='pl-3'>{data?.Place__c}</td>
                          <td className="pl-3">-</td>
                          <td className="pl-3">{data?.Route__c}</td>
                          <td className="pl-3">
                            {data?.Route_Name__c ? data?.Route_Name__c : "-"}
                          </td>
                          <td className="pl-3" >
                            {!data?.FixedPOB__c ? '-' : data.FixedPOB__c}
                          </td >
                        </tr >
                      ))}
                    </tbody >
                  </table >
                </div >
              )
            )}
          </Card.Body >
        </Card >
        <ToastContainer />
      </div >
    </div >
  );
});

export default TpReport;