import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { callAverageApi, summaryCallAvgApi, tourPlanSummaryApi } from "../../services/reports/attendanceClaimDcrApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { pobTrendApi } from "../../services/reports/attendanceClaimDcrApi";
import { actualBudgetApiTokenApi } from "../../services/actualBudgetApiTokenApi";
import { yearlyBudget } from "../../services/actualBudget";
import CommonButton from "../../Common/CommonButton";


const SummaryReport = forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterEmpId, setFilterEmpId] = useState('');
    const [callAvg, setCallAvg] = useState([]);
    const [targetValue, setTargetValue] = useState("");
    const [actualValue, setActualValue] = useState("");
    const [yearlyTargetValue, setYearlyTargetValue] = useState('');
    const [yearlyaActualValue, setYearlyActualValue] = useState('')
    const [pobTrend, setPobTrend] = useState([]);
    const [stationWiseCount, setStationWiseCount] = useState([]);

    const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
    const salesTargetValue = useSelector((state) => state?.actualBudget?.actualValue);
    const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);

    // Budget Vs Sales
    useEffect(() => {
        (async () => {
            if (salesTargetValue) {
                const dataString = salesTargetValue?.split(" - ");
                const targetData = dataString?.[0]?.split(":")?.[1];
                const actualDataData = dataString?.[1]?.split(":")?.[1];
                const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
                const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
                setTargetValue(targetParsed);
                setActualValue(actualParsed);
            }
        })();
    }, [salesTargetValue]);

    // Budget Vs Sales
    useEffect(() => {
        (async () => {
            const responseToken = await actualBudgetApiTokenApi(accessToken);
            const response = await yearlyBudget(empId, responseToken);
            const values = response?.data?.value;
            const dataString = values?.split(" - ");
            const targetData = dataString?.[0]?.split(":")?.[1];
            const actualDataData = dataString?.[1]?.split(":")?.[1];
            const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
            const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
            setYearlyTargetValue(targetParsed);
            setYearlyActualValue(actualParsed);
        })();
    }, [])

    // Field working Days
    useEffect(() => {
        (async () => {
            const response = await tourPlanSummaryApi(accessToken, designation, EmployeeId);
            setStationWiseCount(response?.data?.stationWiseCount || []);
        })();
    }, [accessToken, designation, EmployeeId]);

    const renderRows = () => {
        return Object.entries(stationWiseCount).map(([month, data], index) => {
            console.log("month", month)
            const { month: formattedMonth } = getDayMonth(`${month}-01`); // Assuming month is in YYYY-MM format
            return (
                <tr className="ladder_heading_data" key={index}>
                    <td className='pl-3'>{formattedMonth}</td>
                    <td className='pl-3'>{Object.values(data).reduce((a, b) => a + b, 0)}</td>
                    <td className='pl-3'>{data.Local || 0}</td>
                    <td className='pl-3'>{data["Ex HQ"] || 0}</td>
                    <td className='pl-3'>{data["Out Station"] || 0}</td>
                </tr>
            );
        });
    };

    // call average
    useEffect(() => {
        const fetchData = async () => {
            try {
                const callAverageResponse = await summaryCallAvgApi(accessToken, EmployeeId);
                const data = callAverageResponse?.data;
                console.log("data", data)
                setCallAvg(data);
            } catch (error) {
                console.error('Error fetching call averages:', error);
            }
        };

        fetchData();
    }, [EmployeeId, accessToken, startDate, endDate, filterEmpId]);


    const getDayMonth = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long' };
        const day = date.getDate();
        const month = new Intl.DateTimeFormat('en-US', options).format(date);
        const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);

        return { day, month, weekday };
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('default', { month: 'long' });
    };

    const getMonthNames = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];
        return monthNames[date.getMonth()];
    }
    // pob trend
    useEffect(() => {
        const fetchData = async () => {

            try {
                const actualPlannedResponse = await pobTrendApi(accessToken, EmployeeId);
                console.log("actualPlannedResponse", actualPlannedResponse?.data)
                const filteredData = actualPlannedResponse?.data
                setPobTrend(filteredData);
            } catch (error) {
                console.error('Error fetching actual vs planned data:', error);
            } finally {
                ;
            }
        };

        fetchData();
    }, [EmployeeId, accessToken, designation, filterEmpId, startDate, endDate]);


    const exportToExcel = () => {
        const worksheet = XLSX.utils.table_to_sheet(document.getElementById('table-to-xls'));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Summary Report");
        XLSX.writeFile(workbook, 'summary_report.xlsx');
    };

    return (
        <div>
            <div className="mb-3" style={{ textAlign: '-webkit-right', marginTop: '1rem' }}>
                <span onClick={exportToExcel}>
                    <CommonButton title={"Export to Excel"} />
                </span>
            </div>
            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4" style={{ textAlign: "center" }}>
                                Summary Report
                            </h4>
                            <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                <table style={{ textAlign: "center", width: '100%' }} >
                                    <thead>
                                        <tr className="ladder_heading">
                                            <th className="custom-header">Employee Code</th>
                                            <th className="custom-header">Employee Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="ladder_heading_data">
                                            <td className='pl-3'>2759</td>
                                            <td className='pl-3'>Dawn Francies</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Card className="monthlyClaim_mobile">
                                    <Card.Body>
                                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                                            Budget Vs Sales
                                        </h4>
                                        <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                            <table style={{ textAlign: "center", width: '100%' }}>
                                                <thead>
                                                    <tr className="ladder_heading">
                                                        <th className="custom-header">Month Budget</th>
                                                        <th className="custom-header">Month Sales</th>
                                                        <th className="custom-header">% Ach</th>
                                                        <th className="custom-header">YTD Budget</th>
                                                        <th className="custom-header">YTD Sales</th>
                                                        <th className="custom-header">% Ach</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="ladder_heading_data">
                                                        <td className='pl-3'>{targetValue}</td>
                                                        <td className='pl-3'>{actualValue}</td>
                                                        <td className='pl-3'>-</td>
                                                        <td className='pl-3'>{yearlyTargetValue}</td>
                                                        <td className='pl-3'>{yearlyaActualValue}</td>
                                                        <td className='pl-3'>-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="monthlyClaim_mobile">
                                    <Card.Body>
                                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                                            Field working Days
                                        </h4>
                                        <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                            <table style={{ textAlign: "center", width: '100%' }} >
                                                <thead>
                                                    <tr className="ladder_heading">
                                                        <th className="custom-header">Month</th>
                                                        <th className="custom-header">Total Days</th>
                                                        <th className="custom-header">Local</th>
                                                        <th className="custom-header">Exstation</th>
                                                        <th className="custom-header">Outstation</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {renderRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="monthlyClaim_mobile">
                                    <Card.Body>
                                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                                            POB Trend
                                        </h4>
                                        <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                            <table style={{ textAlign: "center", width: '100%' }} >
                                                <thead>
                                                    <tr className="ladder_heading">
                                                        <th className="custom-header">Month</th>
                                                        <th className="custom-header">Monthly Target</th>
                                                        <th className="custom-header">Monthly Actual</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pobTrend?.map((data, index) => (
                                                        <tr key={index} className="ladder_heading_data">
                                                            <td className='pl-3'>{getMonthName(data?.Month)}</td>
                                                            <td className='pl-3'>{parseFloat(data?.FixedPOBSum).toFixed(2)}</td>
                                                            <td className='pl-3'>{parseFloat(data?.POBSum).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>


                                <Card className="monthlyClaim_mobile">
                                    <Card.Body>
                                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                                            Call Avg Trend
                                        </h4>
                                        <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                            <table style={{ textAlign: "center", width: '100%' }}>
                                                <thead>
                                                    <tr className="ladder_heading">
                                                        <th className="custom-header">Month</th>
                                                        <th className="custom-header">Customer</th>
                                                        <th className="custom-header">Retailer</th>
                                                        <th className="custom-header">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {callAvg.map((item, index) => {
                                                        const { month } = getDayMonth(item?.Month);
                                                        return (
                                                            <tr className="ladder_heading_data">
                                                                <td className='pl-3'>{month}</td>
                                                                <td className='pl-3'>{item?.TotalCustomerActivities}</td>
                                                                <td className='pl-3'>{item?.TotalRetailers}</td>
                                                                <td className='pl-3'>{item?.TotalCalls}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className="monthlyClaim_mobile">
                                    <Card.Body>
                                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                                            Customer Activities
                                        </h4>
                                        <div id="table-to-xls" className="table-wrapper-scroll-y custom-scrollbar">
                                            <table style={{ textAlign: "center", width: '100%' }}>
                                                <thead>
                                                    <tr className="ladder_heading">
                                                        <th className="custom-header">Month</th>
                                                        <th className="custom-header">No of activities</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {callAvg.map((item, index) => {
                                                        const { month } = getDayMonth(item?.Month);
                                                        return (
                                                            <tr className="ladder_heading_data">
                                                                <td className='pl-3'>{month}</td>
                                                                <td className='pl-3'>{item?.TotalOtherTypeActivities}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}
)
export default SummaryReport