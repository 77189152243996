import axios from "axios";
import { configs } from "../../config";

export const getFestivalHoliDayAPiApi = async (accessToken) => {
    const options = {
        url: `${configs?.base_url}/services/data/v58.0/query/?q=SELECT Id,State_Holidays__c,Date__c,Days__c, name FROM Holiday_List__c`,
        method: "get",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };
    try {
        const response = await axios(options);
        return response;
    }
    catch (error) {
        console.log(error)
    }
};