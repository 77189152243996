import React, { useEffect, useState } from "react";
import "./LeaveApplyComponent.css";
import FailButton from "../Common/FailButton";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import { Helmet } from 'react-helmet';
import { Card } from "react-bootstrap";
import "../Common/CommonDropdown.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../Common/Loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { leaveApprovalList } from "../services/leaves/leaveApprovalList";
import check from "../assets/images/check-mark-3280.svg";
import question from "../assets/images/red-question-11802.svg";
import cross from "../assets/images/remove.png";
import Modal from "react-bootstrap/Modal";
import { Document, Page } from "react-pdf";
import { managerLeaveApprove } from "../services/leaves/managerLeaveApprove";
import { fetchAttachmentUrls, fetchBlobBase64 } from "../services/expenses/managerExpensesApi";
import { saveAs } from "file-saver";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const LeaveApproveComponent = () => {
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [rejectReason, setRejectReason] = useState();
  const [rejectionReason, setRejectionReason] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectId, setRejectId] = useState();
  const [contentTypes, setContentTypes] = useState();
  const [attachmentUrls, setAttachmentUrls] = useState();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [rejectReasons, setRejectReasons] = useState([]);



  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);

  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };
  const getList = async () => {
    setLoading(true)
    const response = await leaveApprovalList(accessToken, empId)
    console.log("response--->", response)
    if (response?.data?.done === true) {
      console.log("response?.data?.records", response?.data?.records)
      setLeaveList(response?.data?.records)
      setLoading(false)
    }
  }
  useEffect(() => {
    (async () => {
      await getList()
    })();
  }, [empId, accessToken])

  const pendingMessage = `Pending - ${leaveList.filter(claim => claim.Status__c === "Pending For Approval" || claim.status === "Pending for Release" || claim?.Status__c === "Pending for Release").length}`;
  const approvedMessage = `Approved - ${leaveList.filter(claim => claim.Status__c === "Approved" || claim.Status__c === "Released").length}`;
  const rejectedMessage = `Rejected - ${leaveList.filter(claim => claim.Status__c === "Rejected").length}`;

  const handleshowRejecteModal = (claimId, status) => {
    setRejectId(claimId);
    setRejectModal(!rejectModal);
  };

  // Approve reject api
  // const handleClaimAction = async (dataId, status, data) => {
  //   console.log(dataId, status, data)
  //   if (status === "Rejected" && (!rejectReason || !rejectReason.trim())) {
  //     setLoading(false);
  //     setRejectionReason(true);
  //     return;
  //   }
  //   let leaveObj = {
  //     leaveApplicationId: dataId,
  //     Status: status,
  //     RejectReason: status === ("Approved" || "Released") ? '' : rejectReason,
  //     // isAVP: (designation === "Associate Vice President" || designation === "General Manager") ? true : false
  //   };
  //   console.log("claimObj", leaveObj)
  //   const response = await managerLeaveApprove(accessToken, leaveObj)
  //   setLoading(false);
  //   setRejectModal(false)
  //   console.log("reaponse", response)
  //   if (response?.data?.trim() === "Leave application updated to Pending for Release and approval record created successfully") {
  //     toast.success("Leave application sent for release")
  //   }
  //   else if (response?.data?.trim() === "Leave application is Approved") {
  //     toast.success("Leave application is released")
  //   } else if (response?.data?.trim() === "Leave application updated successfully.") {
  //     toast.error("Leave application rejected")
  //   }
  //   else if (response?.data?.trim() === "Leave application updated to Pending for Release and assigned to manager's manager.") {
  //     toast.success("Leave application send for release")
  //   }
  //   else if (response?.data?.trim() === "Leave application has been released.") {
  //     toast.success("Leave application is released")
  //   }
  //   else {
  //     toast.error("An error occured");
  //   }
  //   getList();
  // };

  // multiple Approve reject api

  const handleClaimAction = async (status) => {
    console.log("1 - Status:", status);
    console.log("Initial rejectReasons:", rejectReasons);
    console.log("Initial selectedIds:", selectedIds);

    // Check if rejection reason is required
    // if (status === "Rejected" && (!rejectReasons || rejectReasons.length === 0)) {
    //   console.log("2 - Rejection reason is missing");
    //   setLoading(false);
    //   setRejectionReason(true); // Prompt user for rejection reason
    //   return;
    // }

    setLoading(true);

    // Prepare the array of leave objects
    const leaveApplications = selectedIds.map(dataId => {
      // Find the rejection reason for this dataId
      const rejectReasonEntry = rejectReasons.find(item => item.claimId === dataId);
      const rejectReason = rejectReasonEntry ? rejectReasonEntry.claimRemark : '';

      return {
        leaveApplicationId: dataId,
        Status: status,
        RejectReason: status === "Rejected" ? rejectReason || '' : rejectReason || '', // Ensure RejectReason is included
      };
    });

    // Construct the payload in the required format
    const payload = {
      leaveApplications: leaveApplications
    };

    console.log("Payload:", payload);

    try {
      const response = await managerLeaveApprove(accessToken, payload);
      console.log("4 - API request complete", response?.data[0]);

      // Handle the response
      // if (response?.data?.trim() === "Leave application updated to Pending for Release and approval record created successfully") {
      //   toast.success("Leave applications sent for release");
      // } else if (response?.data?.trim() === "Leave application is Approved") {
      //   toast.success("Leave applications released");
      // } else if (response?.data[0] === 'Leave application rejected successfully.') {
      //   toast.error("Leave application rejected successfully.");
      //   setRejectModal(!rejectModal);
      // } else if (response?.data === "Leave application updated to Pending for Release") {
      //   toast.success("Leave application updated to Pending for Release");
      // } else if (response?.data?.trim() === "Leave application has been released and leave count updated.") {
      //   toast.success("Leave applications are released");
      // }

      if (response?.data[0] === 'Leave application updated to Pending for Release') {
        toast.success("Leave applications sent for release");
      } else if (response?.data[0] === 'Leave application rejected successfully.') {
        toast.success("Leave application rejected successfully.");
      } else if (response?.data[0] === 'Leave application has been released.') {
        toast.success("Leave application has been released.");
      }
      else {
        toast.error("An error occurred while processing leave applications.");
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred while processing leave applications.");
      setSelectedIds([]);
      setRejectReasons([]);
      setRejectionReason(false); // Close any rejection reason dialogs if applicable
      setRejectModal(false); // Reset reject modal if applicable

    } finally {
      // Ensure loading is stopped even if there's an error
      setLoading(false);
      // Reset states in the finally block to ensure they are cleared after every API call
      setSelectedIds([]);
      setRejectReasons([]);
      setRejectionReason(false); // Close any rejection reason dialogs if applicable
      setRejectModal(false); // Reset reject modal if applicable

      // Call getList to refresh data
      getList();
    }
  };










  // Download file click on View Attachment

  const handleViewAttachment = async (claimId, claimURL) => {
    console.log("---->", claimId)
    setLoading(true)
    try {
      if (claimId) {
        // Fetch the attachment for the specific claim
        const attachment = await fetchAttachmentUrls(claimId, accessToken);

        if (attachment && attachment.VersionData) {
          // Convert the attachment data to base64
          const base64Data = await fetchBlobBase64(attachment.VersionData, accessToken);
          const contentType = attachment.FileType || 'application/octet-stream';

          // Find and update the specific claim
          const updatedClaims = leaveList.map((claim) =>
            claim.Id === claimId
              ? { ...claim, url: base64Data, contentType: contentType }
              : claim
          );

          // Update the state with the new claims array
          setLeaveList(updatedClaims);

          // Call handleApproveClick with the updated URL
          handleApproveClick(base64Data, attachment?.FileType);
        } else {
          // If no attachment found, pass the original claimURL
          handleApproveClick(claimURL);
        }
      }
    } catch (error) {
      console.error("Error fetching attachment:", error);
      // On error, fall back to the original claimURL
      handleApproveClick(claimURL);
    }
    setLoading(false)
  };

  const handleshowApproveModal = () => {
    setShowApproveModal(!showApproveModal);
  };

  const handleApproveClick = async (urls, FileType) => {
    try {
      if (
        FileType.toLowerCase() === "png" ||
        FileType.toLowerCase() === "jpg" ||
        FileType.toLowerCase() === "jpeg"
      ) {
        setContentTypes("png"); // Set to "jpeg" as a generic type for images
        setAttachmentUrls(urls);
      } else if (FileType.toLowerCase() === "pdf") {
        setContentTypes("pdf");
        setAttachmentUrls(urls);
      } else {
        console.log("Unsupported file type");
      }

      setShowApproveModal(true);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  const handleDownloadPdf = () => {
    const byteCharacters = atob(attachmentUrls);
    const byteNumbers = new Array(byteCharacters?.length);
    for (let i = 0; i < byteCharacters?.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    saveAs(blob, "attachment.pdf");
  };

  const downloadImage = (base64Data, contentType) => {
    const url = `data:image/${contentType};base64,${base64Data}`;
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "attachment_image";
    anchor.click();
  };

  const handleCheckboxChange = (dataId) => {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(dataId)) {
        // Remove the ID if it's already in the array (checkbox unchecked)
        return prevSelectedIds.filter(id => id !== dataId);
      } else {
        // Add the ID if it's not in the array (checkbox checked)
        return [...prevSelectedIds, dataId];
      }
    });
  };

  const handleInputChange = (claimId, value) => {
    setRejectReasons(prevRemarks => {
      const existingIndex = prevRemarks.findIndex(item => item.claimId === claimId);

      if (existingIndex !== -1) {
        const updatedRemarks = [...prevRemarks];
        updatedRemarks[existingIndex].claimRemark = value;
        return updatedRemarks;
      } else {
        return [...prevRemarks, { claimId, claimRemark: value }];
      }
    });
  };


  return (
    <>
      <Helmet>
        <title>Leave Apply</title>
      </Helmet>
      <div className="container-fluid mt-2">
        <BackButton title={'Approve Leave'} />
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          >
            <Tab
              eventKey="Pending"
              title={pendingMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {leaveList?.filter(
                    (data) => (data?.Status__c === "Pending For Approval" || data?.Status__c === "Pending for Release")
                  )?.length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Leave Application Status
                        </h4>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
                          <span
                            onClick={() => {
                              const isPendingForRelease = leaveList?.some((data) => data.Status__c === "Pending for Release");
                              handleClaimAction(isPendingForRelease ? "Release" : "Approved");
                            }}
                          >
                            <CommonButton
                              title={"Approve"}
                              width={"5rem"}
                            />
                          </span>
                          <span
                            onClick={() => {
                              handleClaimAction(
                                "Rejected"
                              );
                            }}
                            className="reject-button"
                          >
                            <CommonButton
                              title={"Reject"}
                              width={"5rem"}
                            />
                          </span>

                        </div>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >

                          <table
                            className="w-100"
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                <th className="custom-header">Select</th>
                                <th className="custom-header">From Date</th>
                                <th className="custom-header">To Date</th>
                                <th className="custom-header">Leave Type</th>
                                <th className="custom-header">Session 1</th>
                                <th className="custom-header">Session 2</th>
                                <th className="custom-header">Status</th>
                                <th className="custom-header">Attachment</th>
                                <th className="custom-header">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leaveList?.map((data) => {
                                if (data.Status__c === "Pending For Approval" || data.Status__c === "Pending for Release") {
                                  return (
                                    <tr className="ladder_heading_data" key={data.Id}>
                                      <td>
                                        <label className="custom-checkbox">
                                          <input
                                            type="checkbox"
                                            checked={selectedIds.includes(data.Id)}
                                            onChange={() => handleCheckboxChange(data.Id)}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                      </td>
                                      <td>{data?.Leave_Start_Date__c}</td>
                                      <td>{data?.Leave_End_Date__c}</td>
                                      <td>{data?.Leave_Type__c}</td>
                                      <td>{data?.Session_1__c}</td>
                                      <td>{data?.Session_2__c}</td>
                                      <td>
                                        {(data?.Status__c === "Saved" ||
                                          data?.Status__c === "Pending For Approval" ||
                                          data?.Status__c === "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {data?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(data?.Status__c === "Approved" || data?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {data?.Status__c}
                                      </td>
                                      <td>
                                        {data.hasAttachments__c ?
                                          <span onClick={() => handleViewAttachment(data.Id)}>
                                            <CommonButton
                                              title={"View Attachment"}
                                            />
                                          </span> :
                                          <p>-</p>
                                        }
                                      </td>
                                      <td className="reject-commomn-button">
                                        <input
                                          className="CommonDropdown form-select"
                                          type="text"
                                          style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                          }}
                                          placeholder="Enter Remark"
                                          value={rejectReasons.find(reason => reason.claimId === data.Id)?.claimRemark || ''}
                                          onChange={(e) => handleInputChange(data.Id, e.target.value)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              })}

                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Approved"
              title={approvedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {leaveList?.filter(
                    (data) => (data.Status__c === "Approved" || data.Status__c === "Released" || data.Status__c === "Release")
                  )?.length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Leave Application Status
                        </h4>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >
                          <table
                            className="w-100"
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                <th className="custom-header">From Date</th>
                                <th className="custom-header">To Date</th>
                                <th className="custom-header">Leave Type</th>
                                <th className="custom-header">Session 1</th>
                                <th className="custom-header">Session 2</th>
                                <th className="custom-header">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leaveList?.map((data, index) => {
                                if (data.Status__c === "Approved" || data.Status__c === "Released" || data.Status__c === "Release") {
                                  return (
                                    <tr
                                      className="ladder_heading_data"
                                      key={index}
                                    >
                                      <td>{data?.Leave_Start_Date__c}</td>
                                      <td>{data?.Leave_End_Date__c}</td>
                                      <td>{data?.Leave_Type__c}</td>
                                      <td>{data?.Session_1__c}</td>
                                      <td>{data?.Session_2__c}</td>
                                      <td>
                                        {" "}
                                        {(data?.Status__c === "Saved" ||
                                          data?.Status__c ===
                                          "Pending For Approval" ||
                                          data?.Status__c ===
                                          "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {data?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(data?.Status__c === "Approved" || data?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {data?.Status__c}
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Deleted"
              title={rejectedMessage}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                  {leaveList?.filter(
                    (data) => (data.Status__c === "Rejected")
                  )?.length > 0 ? (
                    <Card className="monthlyClaim_mobile">
                      <Card.Body>
                        <h4 className="mb-4" style={{ textAlign: "center" }}>
                          Leave Application Status
                        </h4>
                        <div
                          id="table-to-xls"
                          className="table-wrapper-scroll-y custom-scrollbar"
                        >
                          <table
                            className="w-100"
                            style={{ textAlign: "center" }}
                          >
                            <thead>
                              <tr className="ladder_heading">
                                <th className="custom-header">From Date</th>
                                <th className="custom-header">To Date</th>
                                <th className="custom-header">Leave Type</th>
                                <th className="custom-header">Session 1</th>
                                <th className="custom-header">Session 2</th>
                                <th className="custom-header">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leaveList?.map((data, index) => {
                                if (data.Status__c === "Rejected") {
                                  return (
                                    <tr
                                      className="ladder_heading_data"
                                      key={index}
                                    >
                                      <td>{data?.Leave_Start_Date__c}</td>
                                      <td>{data?.Leave_End_Date__c}</td>
                                      <td>{data?.Leave_Type__c}</td>
                                      <td>{data?.Session_1__c}</td>
                                      <td>{data?.Session_2__c}</td>
                                      <td>
                                        {" "}
                                        {(
                                          data?.Status__c ===
                                          "Pending For Approval" ||
                                          data?.Status__c ===
                                          "Pending for Release") && (
                                            <img
                                              className="MTP_approved"
                                              src={question}
                                              alt=""
                                            />
                                          )}
                                        {data?.Status__c === "Rejected" && (
                                          <img
                                            className="MTP_approved"
                                            src={cross}
                                            alt=""
                                          />
                                        )}
                                        {(data?.Status__c === "Approved" || data?.Status__c === "Released") && (
                                          <img
                                            className="MTP_approved"
                                            src={check}
                                            alt=""
                                          />
                                        )}
                                        {data?.Status__c}
                                      </td>
                                    </tr>
                                  );
                                }
                              }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  ) : (
                    <h6
                      className="mt-5"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      No records to display
                    </h6>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div >

      <Modal
        show={rejectModal}
        onHide={handleshowRejecteModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>Enter Reason For Rejection</h5>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row">
            <p className="PlanMTP_leftHead">Enter the reason</p>
            <div className="container">
              <input
                type="text"
                className="CommonDropdown form-select"
                onChange={handleRejectReasonChange}
                style={{
                  width: "100%",
                  color: "rgb(46, 49, 146)",
                  fontSize: "14px",
                  backgroundImage: "none",
                  paddingRight: "14px",
                }}
              />
              {rejectionReason && (
                <p style={{ color: "red" }}>
                  Please enter a reason for rejection.
                </p>
              )}
            </div>
            {/* <input type="text" placeholder="Enter the reason" onChange={handleRejectReasonChange} /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="col-6" style={{ marginLeft: "-5.5px" }}>
              <span
                onClick={() => {
                  handleClaimAction(rejectId, "Rejected", selectedClaim);
                }}
              >
                <CommonButton title={"Yes"} width={"100%"} />
              </span>
            </div>
            <div className="col-6" onClick={handleshowRejecteModal}>
              <FailButton
                buttonText={"No"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* file show modal */}
      <Modal
        show={showApproveModal}
        onHide={handleshowApproveModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="doctor360Modal_survey"
      >
        <Modal.Header closeButton className="text-center">
          <h5>View Attachment</h5>
          {(contentTypes === "png" ||
            contentTypes === "jpg" ||
            contentTypes === "jpeg") && (
              <span onClick={() => downloadImage(attachmentUrls, "jpeg")}>
                <DownloadForOfflineIcon
                  style={{
                    fontSize: 30,
                    color: "#00a87e",
                    float: "right",
                    marginLeft: "1rem",
                  }}
                />
              </span>
            )}
          {contentTypes === "pdf" && (
            <span onClick={handleDownloadPdf}>
              <DownloadForOfflineIcon
                style={{
                  fontSize: 30,
                  color: "#00a87e",
                  float: "right",
                  marginLeft: "1rem",
                }}
              />
            </span>
          )}
          {/* <span onClick={() => downloadImage(attachmentUrls)}>
            <DownloadForOfflineIcon style={{ fontSize: 30, color: '#00a87e', float: 'right', marginLeft: '1rem' }} />
          </span> */}
        </Modal.Header>
        <Modal.Body className="show-grid">
          <div className="row text-center">
            {/* <img src={attachmentUrls} alt="Attachment" className="img-fluid" /> */}
            {(contentTypes === "png" ||
              contentTypes === "jpg" ||
              contentTypes === "jpeg") && (
                <img
                  src={`data:image/jpeg;base64,${attachmentUrls}`}
                  alt="Attachment"
                  className="img-fluid"
                />
              )}
            {contentTypes === "pdf" && (
              // <embed src={`data:application/pdf;base64,${attachmentUrls}`} type="application/pdf" width="100%"/>
              <Document file={`data:application/pdf;base64,${attachmentUrls}`}>
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="doctor360Modal_survey_button">
          <div className="row w-100" style={{ textAlign: "center" }}>
            <div className="" onClick={handleshowApproveModal}>
              <CommonButton
                title={"Ok"}
                height={"2rem"}
                width={"100%"}
                variant="contained"
                className="doctorName_button"
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      {loading && <Loader />}
    </>
  );
};

export default LeaveApproveComponent;
