/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import "./UploadRoutes.css";
import CommonButton from '../Common/CommonButton';
import { createRoutApi } from '../services/createRout/createRoutApi';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Common/Loader';
import BackButton from "../Common/BackButton";
import upload from "../assets/images/file.png"


const UploadRoutes = () => {
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState();
    const [fileError, setFileError] = useState('');

    const [formData, setFormData] = useState({
        Division: '',
        HQ: '',
        Route: '',
        RouteDescription: '',
        StartingPoint: '',
        MidPoint: '',
        EndPoint: '',
        TotalDistanceCovered: '',
        TypeofStation: '',
        empCode: ''
    });
    const fieldLabels = {
        Division: 'Division',
        HQ: 'HQ',
        Route: 'Route',
        RouteDescription: 'Route Description',
        StartingPoint: 'Starting Point',
        MidPoint: 'Mid Point',
        EndPoint: 'End Point',
        TotalDistanceCovered: 'Total Distance Covered',
        TypeofStation: 'Type of Station',
        empCode: 'Employee Code',
    };

    const accessToken = useSelector((state) => state?.accessToken?.accessToken);

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        setFileName(file.name);
        setFileError('');  // Clear the file error when a file is selected

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                if (binaryStr) {
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet);

                    const formattedData = {
                        Routes: data.map(item => ({
                            Division: item['Division'] || '',
                            HQ: item['HQ'] || '',
                            Route: item['Route'] || '',
                            RouteDescription: item['Route Description'] || '',
                            StartingPoint: item['Starting Point'] || '',
                            MidPoint: item['Mid Point'] || '',
                            EndPoint: item['End Point'] || '',
                            TotalDistanceCovered: String(item['Total Distance Covered']) || '',
                            TypeofStation: item['Type of Station (Local /Ex. / OS)'] || '',
                            empCode: String(item['Emp Code']) || ''
                        }))
                    };

                    setJsonData(formattedData);
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async () => {
        if (!fileName) {  // Check if no file is selected
            setFileError("Please select a file before submitting");
            return;  // Prevent submission if no file is selected
        }

        setLoading(true);
        console.log("data", jsonData);

        const response = await createRoutApi(accessToken, jsonData);

        console.log("response", response);
        if (response) {
            toast.success("Data uploaded successfully");
        } else {
            toast.error("An error occurred");
        }

        setLoading(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Allow only numeric input for specific fields
        if (name === 'TotalDistanceCovered' || name === 'empCode') {
            if (/^\d*$/.test(value)) { // Check if value is numeric
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = `${fieldLabels[key]} is required`;
            }
        });
        return newErrors;
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Transform form data into the required payload format
        const payload = {
            Routes: [formData]
        };

        setLoading(true);
        console.log('Form Data Submitted:', payload);

        const response = await createRoutApi(accessToken, payload);
        console.log("response", response);
        if (response) {
            toast.success("Data submitted successfully");
        } else {
            toast.error("An error occurred");
        }

        // Clear form after submission
        setFormData({
            Division: '',
            HQ: '',
            Route: '',
            RouteDescription: '',
            StartingPoint: '',
            MidPoint: '',
            EndPoint: '',
            TotalDistanceCovered: '',
            TypeofStation: '',
            empCode: ''
        });

        setErrors({});
        setLoading(false);
    };

    const handlePhotoIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div>
            <div className="container-fluid mt-2">
                <div className="row mt-3 mb-4">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mb-3">
                        <BackButton title={"Upload Routes"} />
                    </div>
                </div>
                <div className="markAttendance Approve_plansTab_heading">
                    <Tabs
                        defaultActiveKey="Pending"
                        id="fill-tab-example"
                        className="my-3 markAttendance_tab Approve_plansTab"
                        fill
                        style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
                    >
                        <Tab
                            eventKey="Pending"
                            title={"Multiple"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="" style={{ textAlign: "center" }}>
                                        Upload Excel File
                                    </h4>
                                    <div className="markAttendance_section container-fluid">
                                        <img alt="upload image" src={upload} style={{ height: '3rem' }} onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" />

                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileUpload}
                                        />
                                        {fileError && <p className="error-message" style={{ color: 'red', textAlign: "center" }}>{fileError}</p>}
                                    </div>
                                    <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                                    <span className="leaveApply_button" onClick={handleSubmit}>
                                        <CommonButton title={"Submit file"} width={'18rem'} />
                                    </span>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab
                            eventKey="Deleted"
                            title={"Single"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="mb-4" style={{ textAlign: "center" }}>
                                        Upload Route
                                    </h4>
                                    <div className="formContainer">
                                        <form onSubmit={formSubmit}>
                                            <div className="form-row">
                                                {Object.keys(formData).map((field, index) => (
                                                    <div className="form-group" key={field}>
                                                        <p className="PlanMTP_leftHead" htmlFor={field} style={{ textAlign: 'start' }}>
                                                            {fieldLabels[field]}
                                                        </p>
                                                        <input
                                                            className="CommonDropdown form-select"
                                                            type="text"
                                                            style={{
                                                                width: "100%",
                                                                color: "rgb(46, 49, 146)",
                                                                fontSize: "14px",
                                                                backgroundImage: "none",
                                                                paddingRight: "14px",

                                                            }}
                                                            id={field}
                                                            name={field}
                                                            value={formData[field]}
                                                            onChange={handleInputChange}

                                                        />
                                                        {errors[field] && <p className="error-message">{errors[field]}</p>}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ marginTop: '1rem' }}>
                                                <span className="leaveApply_button" onClick={formSubmit}>
                                                    <CommonButton title={"Submit"} width={'18rem'} value="Submit" />
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loader />}
        </div>
    );
};

export default UploadRoutes;
