import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import DoughnutChart from "../Components/DoughnutChart";
import DailyAgenda from "../Components/DailyAgenda";
import BirthdayReminder from "../Components/BirthdayReminder";
import CommonMultiAxisLineChart from "../Common/CommonMultiAxisLineChart";
import { Card } from "react-bootstrap";
import svg3 from "./../assets/images/modules/eDetailing.svg";
import svg5 from "./../assets/images/modules/leaves.svg";
import svg6 from "./../assets/images/modules/monthly-tour-plan.svg";
import svg7 from "./../assets/images/modules/report.svg";
import CommonButton from "../Common/CommonButton";
import { Link } from "react-router-dom";
import emailIcon from "./../assets/images/mail-2553.svg";
import mobileIcon from "./../assets/images/mobile-phone-2640.svg";
import profileIcon from "./../assets/images/user-3297.svg";
import svg4 from "./../assets/images/modules/expense-claim.svg";
import { Helmet } from "react-helmet";
import { getFormatedDate } from "../Common/functions";
import AttendanceModal from "../Common/AttendanceModal";
import { useSelector, connect, useDispatch } from "react-redux";
import { signOutApi } from "../services/signOutApi";
import { CustomIdAction } from "../Redux/actions/CustomIdAction";
import RegularizationModal from "../Common/RegularizationModal";
import getRegularizationsApi from "../services/getRegularizationsApi";
import Loader from "../Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import suitcase from "./../assets/images/suitcase.png";
import svg10 from "./../assets/images/modules/input-inventory.svg";
import defaultImage from "../assets/images/Default-Profile.jpg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import designationProf from "./../assets/images/designation.svg";
import MultiColumnReport from "../Components/Reports/MultiColumnReport";
import { getCallAverageApi } from "../services/home/getCallAverageApi";
import { getTotalPobApi } from "../services/home/getTotalPobApi";
import FeedbackData from "./FeedbackData";
import LoginModalPopUp from "../Common/LoginModalPopUp";
import GaugeChart from "../Common/GaugeChart";
import { actualBudget, yearlyBudget } from "../services/actualBudget";
import { actualBudgetApiTokenApi } from "../services/actualBudgetApiTokenApi";
import { actualBudgetAction } from "../Redux/actions/actualBudgetAction";
import { checkAttendanceApi } from "../services/home/checkAttendanceApi"

function Home({ CustomIdAction, actualBudgetAction }) {
  const { handleLogoutAuth } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formatedDate, setFormatedDate] = useState(getFormatedDate(new Date()));
  const [allDates, setAllDates] = useState([]);
  const [showRegModal, setShowRegModal] = useState(false);
  const [rcpaTrendValue, setRcpaTrendValue] = useState("Month");
  const [loading, setLoading] = useState(false);
  const [profileImage1, setProfileImage1] = useState();
  const fileInputRef = useRef(null);
  const [profImage, setProfImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [callCoverage, setCallCoverage] = useState([]);
  const [pobTarget, setPobTarget] = useState([])
  const [targetValue, setTargetValue] = useState("");
  const [actualValue, setActualValue] = useState("");
  const [salesTargetValues, setSalesTargetValues] = useState('');
  const [salesActualValue, setSalesActualValue] = useState('');
  const [todayDate, setTodayDate] = useState('');
  const [checkLoginDate, setCheckLoginDate] = useState('');
  const [yearlyTargetValue, setYearlyTargetValue] = useState('');
  const [yearlyaActualValue, setYearlyActualValue] = useState('')
  const navigate = useNavigate();

  const handleLoginModal = () => {
    setShowLoginModal(false);
  };
  const dispatch = useDispatch();
  const details = useSelector((state) => state?.userDetails?.details);
  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const loginTime = useSelector((state) => state?.signInTime?.customId);

  // const profileUrl = useSelector(
  //   (state) => state?.profileImageUrl?.profileImageUrl
  // );
  const salesTargetValue = useSelector((state) => state?.actualBudget?.actualValue);

  // useEffect(() => {
  //   const loginModal = localStorage.getItem("loginModal");
  //   if (loginModal === "true") {
  //     setShowLoginModal(true);
  //   }
  // }, []);

  const handleAttendanceModal = () => {
    //     setIsSignIn(true);
    setShowAttendanceModal(!showAttendanceModal);
  };

  const handleDateChange = (value) => {
    setFormatedDate(value);
  };

  const datesArray = [];

  for (let i = 3; i >= 0; i--) {
    let date = new Date();
    let tempDate = date.setDate(date.getDate() - i);
    datesArray.push({
      id: Math.random(),
      date: getFormatedDate(new Date(tempDate)),
    });
  }

  const modules = [
    {
      image: svg6,
      title: "New Tour plan",
      link: "/monthlyTour",
    },

    {
      image: svg4,
      title: "Expense Claim",
      link: "/expenseClaim",
    },

    {
      image: svg3,
      title: "EDetailing",
      link: "/eDetailing/Dr. Rajeev Verma",
    },
    {
      image: svg7,
      title: "Reports",
      link: "/reports",
    },
    {
      image: svg5,
      title: "Leaves",
      link: "/leaves",
    },
    {
      image: svg10,
      title: "Customers",
      link: "/customers",
    },
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 86400000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime24Hour = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes are less than 10
    return hours + ':' + minutes;
  };

  // function runAtSpecificTime(fn) {
  //   const now = new Date();
  //   const specificTime = new Date(now);
  //   specificTime.setHours(0, 1, 0, 0);;
  //   if (now.getTime() > specificTime.getTime()) {
  //     specificTime.setDate(specificTime.getDate() + 1);
  //   }

  //   const timeUntilSpecificTime = specificTime.getTime() - now.getTime();
  //   const timeoutID = setTimeout(() => {
  //     const lastRunDate = localStorage.getItem('lastRunDate');
  //     const today = new Date().toISOString().split('T')[0];
  //     if (lastRunDate !== today) {
  //       fn();
  //       localStorage.setItem('lastRunDate', today);
  //     }
  //     runAtSpecificTime(fn);
  //   }, timeUntilSpecificTime);

  //   return timeoutID;
  // }

  // const updateDateAndState = () => {
  //   const today = new Date().toISOString().split('T')[0];
  //   setTodayDate(today);
  //   localStorage.setItem('loginDate', today);
  //   const staticDate = localStorage.getItem("compareDate");
  //   setCheckLoginDate(staticDate);
  // };

  // useEffect(() => {
  //   updateDateAndState();
  //   const timeoutID = runAtSpecificTime(() => {
  //     setIsSignIn(false);
  //     localStorage.setItem('isSignedIn', false);
  //     updateDateAndState();
  //   });

  //   return () => clearTimeout(timeoutID);
  // }, []);

  useEffect(() => {
    (async () => {
      setLoading(true)
      await CustomIdAction(accessToken, empId);
      const isMarked = JSON.parse(localStorage.getItem("isMarked"));
      const checkDate = localStorage.getItem("loginDate");
      let attendanceDate = new Date().toISOString().split('T')[0];
      const response = await checkAttendanceApi(accessToken, EmployeeId, attendanceDate)
      if (response?.data.trim() === "Log in and log out are both missing.") {
        setIsSignIn(false)
      }
      else if (response?.data.trim() === "Log in is filled, but log out is missing.") {
        setIsSignIn(true)
      }
      else if (response?.data?.trim() === "Log in and log out are both filled.") {
        setIsSignIn(false)
      }
      else if (response?.data?.trim() === "No attendance record found for the given EmpId and date.") {
        setIsSignIn(false)
      }
      setLoading(false)
      // if (isMarked && checkLoginDate === checkDate) {
      //   setIsSignIn(true); 
      //   localStorage.setItem("isSignedIn", true);
      // }
    })();
  }, [accessToken, empId, CustomIdAction, EmployeeId]);

  // useEffect(() => {
  //   if (checkLoginDate !== todayDate) {
  //     setIsSignIn(false);
  //     localStorage.setItem("compareDate", todayDate);
  //   }
  // }, [todayDate, checkLoginDate, navigate]);

  const Logout = formatTime24Hour(currentDateTime);
  const blankRecordId = useSelector((state) => state?.blankRecordId?.customId);
  const empName = useSelector(
    (state) => state?.userDetails?.details?.Employee_Name__c
  );

  const getCurrentMonthData = () => {
    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'short' });
    const currentYear = currentDate.getFullYear(); // Get current year
    const totalDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const xAxis = [];
    let count = 0;

    let isMonthAdded = false;

    for (let day = 1; day <= totalDaysInMonth; day++) {
      const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      if (currentDay.getDay() !== 0) {
        if (!isMonthAdded) {
          xAxis.push(`${currentMonthName} ${currentYear}`); // Concatenate month and year
          isMonthAdded = true;
        }
        count++;
      }
    }

    return {
      xAxis: xAxis,
      count: [count * 8]
    };
  };

  const currentMonthData = getCurrentMonthData();


  useEffect(() => {
    (async () => {
      const response = await getCallAverageApi(accessToken, EmployeeId);
      if (response?.status === 200) {
        setCallCoverage(response?.data);
      }
    })();
  }, [EmployeeId, accessToken]);

  useEffect(() => {
    (async () => {
      const response = await getTotalPobApi(accessToken, EmployeeId);
      if (response?.status === 200) {
        setPobTarget(response?.data);
      } else {
      }
    })();
  }, [EmployeeId, accessToken]);


  // useEffect(() => {
  //   const storedIsSignedIn = localStorage.getItem("isSignedIn");
  //   setIsSignIn(storedIsSignedIn === "true");
  // }, []);

  const parseTime = (timeStr) => {
    if (!timeStr) return null;

    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };


  const handleLogout = (callback) => {
    const logInTimeStr = loginTime;
    const logOutTimeStr = Logout;
    // let isPresent = false;
    const logInTime = parseTime(logInTimeStr);
    const logOutTime = parseTime(logOutTimeStr);

    if (logInTime && logOutTime) {
      const timeDifference = logOutTime - logInTime;
      console.log('Time Difference (ms):', timeDifference);

      const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
      console.log('Time Difference (hours):', timeDifferenceInHours);
      const eightHoursInMs = 8 * 60 * 60 * 1000;
      const isPresent = timeDifference >= eightHoursInMs;
      console.log("isPresent", isPresent)
      const status = isPresent ? "P" : "A"
      signOutApi(blankRecordId, accessToken, empName, Logout, empId, status);
      localStorage.setItem("isSignedIn", false);
      localStorage.setItem("isMarked", false)
      setIsSignIn(false);
    }
    toast.success("Logout successfully");
    // toast.success("Logout successfully");
  };

  // automatic logout
  useEffect(() => {
    // const handleDateChange = () => {
    const lastLoggedInDateString = sessionStorage.getItem("lastLoggedInDate");
    if (!lastLoggedInDateString) {
      handleLogoutAuth();
      navigate("/login");
      document.title = "Hester Pharma"
      return;
    }

    const lastLoggedInDate = new Date(lastLoggedInDateString);
    const currentDate = new Date();
    const differenceInMs = currentDate.getTime() - lastLoggedInDate.getTime();
    const oneDayMs = 24 * 60 * 60 * 1000;
    if (differenceInMs > oneDayMs) {
      handleLogoutAuth();
      navigate("/login");
      document.title = "Hester Pharma"
    }
    // };

    window.addEventListener("focus", handleDateChange);
    return () => {
      window.removeEventListener("focus", handleDateChange);
    };
  }, []);



  const handleRegularizeModal = async () => {
    setShowRegModal(!showRegModal);
    const records = await getRegularizationsApi(accessToken, empId, EmployeeId);
    // console.log(records?.data?.totalSize)
    setErrorMessage(records?.data?.totalSize);
    const test = records?.data?.records;
    const dates = test?.map((record) => record?.Date1__c);
    const regularizationId = records?.data?.records[0]?.Id;
    localStorage.setItem("regularizationId", regularizationId);
    setAllDates(test);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // setLoading(true);
  //       if (!profileUrl) {
  //         setProfImage(true);
  //         setProfileImage1(defaultImage);
  //         // setLoading(false);
  //       } else {
  //         setProfileImage1(profileUrl);
  //         // setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching or updating profile image:", error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [dispatch, accessToken, EmployeeId]);

  useEffect(() => {
    (async () => {
      const responseToken = await actualBudgetApiTokenApi(accessToken);
      // console.log("response-->", responseToken?.data?.access_token)
      if (responseToken) {
        const response = await actualBudgetAction(empId, responseToken);
        const dataString = response?.data?.value.split(" - ")
        const targetData = dataString?.[0]?.split(":")?.[1];

        const actualDataData = dataString?.[1]?.split(":")?.[1];
        const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
        const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
        const responseYTD = await yearlyBudget(empId, responseToken);
        const values = responseYTD?.data?.value;
        const dataStringYTD = values?.split(" - ");
        const targetDataYTD = dataStringYTD?.[0]?.split(":")?.[1];
        const actualDataYTD = dataStringYTD?.[1]?.split(":")?.[1];
        const targetParsedYTD = parseFloat(targetDataYTD?.split(",")?.join('')) || 0;
        const actualParsedYTD = parseFloat(actualDataYTD?.split(",")?.join('')) || 0;
        setYearlyTargetValue(targetParsedYTD);
        setYearlyActualValue(actualParsedYTD);
        // setTargetValue(targetData?.split(",")?.[0] + targetData?.split(",")?.[1]);
        // setActualValue(actualDataData?.split(",")?.[0] + actualDataData?.split(",")?.[1])
        setTargetValue(targetParsed)
        setActualValue(actualParsed)
        if (response?.data) {
          const loginModal = localStorage.getItem("loginModal");
          if (loginModal === "true") {
            setShowLoginModal(true);
          }
          // setShowLoginModal(true);
        }
      }
    })();
  }, [accessToken, empId, actualValue, targetValue]);

  useEffect(() => {
    (async () => {
      if (salesTargetValue) {
        const dataString = salesTargetValue.split(" - ");
        const targetData = dataString?.[0]?.split(":")?.[1];
        const actualDataData = dataString?.[1]?.split(":")?.[1];
        const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
        const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
        setSalesTargetValues(targetParsed);
        setSalesActualValue(actualParsed);
      }
    })();
  }, [salesTargetValue]);

  useEffect(() => {
    // console.log("value----->", salesActualValue, salesTargetValues)
  }, [salesActualValue, salesTargetValues])




  return (
    <div className="home_outer">
      <AttendanceModal
        showModal={showAttendanceModal}
        handleClose={handleAttendanceModal}
        dates={datesArray}
        setIsSignIn={setIsSignIn}
        handleDateChange={handleDateChange}
        modalText="Mark your attendance"
      />
      <RegularizationModal
        showModal={showRegModal}
        handleClose={handleRegularizeModal}
        regularizations={allDates}
        dates={allDates}
        errorMessage={errorMessage}
      />
      <LoginModalPopUp
        showModal={showLoginModal}
        handleClose={handleLoginModal}
        modalHeading={"Target"}
        modalContent={"You have successfully logged in."}
        actualValue={actualValue}
        targetValue={targetValue}
        yearlyTargetValue={yearlyTargetValue}
        yearlyaActualValue={yearlyaActualValue}
      />
      <Helmet>
        <title>Hester</title>
      </Helmet>
      <div className="home_center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-6  col-lg-4  col-12 my-2 custom-col">
              <Card
                className="Profile_card"
                style={{ height: "11rem", textAlign: "inherit", paddingTop: "0px", paddingBottom: "5px" }}
              >
                <Card.Body>
                  <div className="row" style={{ textWrap: "nowrap" }}>
                    <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center">
                      {/* {profileImage1 && (
                        <img
                          src={
                            profImage
                              ? profileImage1
                              : `data:image/jpeg;base64,${profileImage1}`
                          }
                          className="profileImg"
                          alt=""
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />*/}
                      <img
                        src={defaultImage}
                        className="profileImg"
                        alt=""
                      />
                    </div>
                    <div style={{ marginTop: "0px" }} className="col-lg-8 col-md-8 col-12">
                      <h5>
                        <img
                          src={profileIcon}
                          style={{
                            height: "1rem",
                            marginRight: "4px",
                            marginBottom: "2px",
                          }}
                          alt="image2"
                        />
                        {details?.Name}
                      </h5>
                      <p style={{ marginTop: "0" }} className="m-0 profile_font">
                        <img
                          src={mobileIcon}
                          style={{
                            height: "1rem",
                            marginRight: "5px",
                            marginBottom: "3px",
                          }}
                          alt="image1"
                        />
                        {details?.Mobile_Number__c}
                      </p>
                      <p className="m-0 profile_font">
                        <img
                          src={emailIcon}
                          style={{
                            height: "1rem",
                            marginRight: "5px",
                            marginBottom: "3px",
                          }}
                          alt="image1"
                        />
                        {details?.Email}
                      </p>
                      <p className="m-0 profile_font" style={{ fontFamily: 'GilroyMedium' }}>
                        <img
                          src={suitcase}
                          style={{
                            height: "1rem",
                            marginRight: "5px",
                            marginBottom: "3px",
                          }}
                          alt="image1"
                        />
                        Division: {details?.Division__c}
                      </p>
                      <p className="m-0 profile_font" style={{ fontFamily: 'GilroyMedium' }}>
                        <img
                          src={designationProf}
                          style={{
                            height: "1rem",
                            marginRight: "5px",
                            marginBottom: "3px",
                          }}
                          alt="image1"
                        />
                        Designation: {details?.Designation__c}
                      </p>
                      {/* <p style={{ fontSize: "15px" }}>
                        <b style={{ marginLeft: '1.2rem' }}>Division: </b>{details?.Division__c}
                      </p> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 my-2 col-12 text-center custom-col">
              <Card className="Agenda_back">
                <Card.Body>
                  <h3>Agenda</h3>
                  <h6 className="mb-4">Mark Attendance</h6>
                  <div className="row">
                    {isSignIn ? (
                      <>
                        <div className="col-12 col-md-4 mb-2 col-lg-4 col-sm-12">
                          <Link to={"/markAttendance"}>
                            <CommonButton
                              title={"Start Your Day"}
                              className="startDay"
                              block
                              width={"9rem"}
                            />
                          </Link>
                        </div>

                        <div className="col-12 col-md-4 mb-2 col-lg-4 col-sm-6">
                          <span onClick={handleRegularizeModal}>
                            <CommonButton
                              title={"Regularisation"}
                              width={"9rem"}
                            // style={{ width: "100%" }}
                            />
                          </span>
                        </div>

                        <div className="col-12 col-md-4 mb-2 col-lg-4 col-sm-6">
                          <span onClick={handleLogout}>
                            <CommonButton
                              title={"End Day"}
                              width={"9rem"}
                            // style={{ width: "100%" }}
                            />
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 col-md-6 mb-2 col-lg-6 col-sm-6">
                          <span onClick={() => handleAttendanceModal()}>
                            <CommonButton
                              title={"Mark Attendance"}
                              width={"9rem"}
                            // style={{ width: "100%" }}
                            />
                          </span>
                        </div>

                        <div className="col-12 col-md-6 mb-2 col-lg-6 col-sm-6">
                          <span onClick={handleRegularizeModal}>
                            <CommonButton
                              title={"Regularisation"}
                              width={"9rem"}
                            // style={{ width: "100%" }}
                            />
                          </span>
                        </div>
                        {/*  <div className="col-12 col-md-4 col-lg-4 col-sm-12">
                          <Link to={"/markAttendance"}>
                            <CommonButton
                              title={"Start Your Day"}
                              className="startDay"
                              block
                              width={"9rem"}
                            />
                          </Link>
                        </div>   */}
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12  col-lg-4  col-12 my-2 full-width-col">
              <div className="card birthday_reminder text-center justify" style={{ height: '11rem' }}>
                <BirthdayReminder />
              </div>
            </div>
          </div>
          <div className="row m-2">
            <h4 style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Shortcuts</h4>
            <div className="col-12 mb-3">
              <div className="row justify-content-center">
                {modules.map((module, index) => (
                  <div
                    key={index}
                    className="col-lg-2 col-md-3 col-sm-4 col-6 mb-2 text-center"
                  >
                    <Card
                      className="home_modulesCard"
                      style={{ borderRadius: "2rem" }}
                    >
                      <Card.Body className="pl-0 pr-0">
                        <Link className="module_link" to={module.link}>
                          <div className="icon-boxme">
                            <img style={{ marginTop: "15px" }} src={module.image} alt={`image_${index}`} />
                            <h5>{module.title}</h5>
                          </div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row m-2 mt-4">

            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
              <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Call Average</h4>
              <MultiColumnReport
                description={["Achieved", "Target"]}
                xAxis={currentMonthData?.xAxis}
                Total={[8]}
                Achieved={[callCoverage?.callAverage]}
                colors={["rgb(44, 175, 254)", "#4FC0D0"]}
              />
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
              <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>POB(Actual Vs Achieved)</h4>
              <MultiColumnReport
                description={["Actual", "Target"]}
                xAxis={currentMonthData?.xAxis}
                Total={[pobTarget?.PlannedPOB]}
                Achieved={[pobTarget?.ActualPOB]}
                colors={["rgb(44, 175, 254)", "#4FC0D0"]}
              />
            </div>


            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
              <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Budget V/S Actual</h4>
              <GaugeChart
                values={[salesTargetValues, salesActualValue]}
                title={"Target"}
                P1={"Monthly target"}
                P2={"Target achieved"}
              />

              {/* <MultiColumnReport
                description={["Actual", "Target"]}
                xAxis={currentMonthData?.xAxis}
                Total={[0]}
                Achieved={[0]}
                colors={["rgb(44, 175, 254)", "#4FC0D0"]}
              /> */}
            </div>


          </div>
          <div className="row m-2 mt-4">
            <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Development Rating</h4>
            <FeedbackData
              EmployeeId={EmployeeId}
              accessToken={accessToken}
            />
          </div>
          {/* <div className="row m-2 mt-4">
            <h4 style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Dashboards</h4>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3 report_padding">
              <GaugeChart
                values={[100, 65]}
                title={"Dr. Coverage Compliance"}
                P1={"Doctor Coverge"}
                P2={"Visit Frequency"}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3 report_padding">
              <GaugeChart
                values={[100, 35]}
                title={"RCPA"}
                P1={"MTP Visited"}
                P2={"Completed"}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3 report_padding">
              <GaugeChart
                values={[100, 65]}
                title={"Input Distribution"}
                P1={"Total"}
                P2={"Distributed"}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3 report_padding">
              <GaugeChart
                values={[100, 72]}
                title={"Feedbacks"}
                P1={"Planned"}
                P2={"Completed"}
              />
            </div>
          </div> */}

        </div>

      </div>
      <div className="home_footer"></div>
      {loading && <Loader />}
      <ToastContainer />
    </div>
  );
}

export default connect(null, {
  CustomIdAction,
  actualBudgetAction
})(Home);
