import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { actualPlannedApi } from "../../services/reports/attendanceClaimDcrApi";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx'; // Import XLSX library
import CommonButton from "../../Common/CommonButton";
import { reportPOBReportDownloadApi } from "../../services/reports/reportForPOBApi";
import Loader from "../../Common/Loader";
import { toast, ToastContainer } from "react-toastify";

const ActualPlannedReport = forwardRef((props, ref) => {
    const [actualPlanned, setActualPlanned] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterEmpId, setFilterEmpId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [jointWork, setJointWork] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [excloading, setExcloading] = useState(false)
    const [lastRecordId, setLastRecordId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const empId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
    const division = useSelector((state) => state?.userDetails?.details?.Division__c);


    // useEffect(() => {
    //     const fetchData = async () => {
    // setLoading(true);
    //       const limitSize = 2000;
    //       let lastId = null;     
    //       let allData = [];

    //       try {
    //         while (true) {
    //           const actualPlannedResponse = await actualPlannedApi(
    //             accessToken,
    //             EmployeeId,
    //             designation,
    //             limitSize,
    // lastId
    //           );

    //           // Assuming your API returns data and `lastId` for pagination
    //           const fetchedData = JSON.parse(actualPlannedResponse?.data || "[]");
    //           console.log("Fetched Data:", fetchedData);

    //           // If no data is returned, break the loop
    //           if (fetchedData.length === 0) {
    //             break;
    //           }

    //           // Accumulate data
    // allData = [...allData, ...fetchedData];

    //           // Update lastId to the ID of the last fetched record
    //           lastId = fetchedData[fetchedData.length - 1]?.Id; // Adjust this according to your data structure
    //         }

    //         // Filter and sort the accumulated data
    //         const filteredData = allData.filter(item => {
    //           const itemDate = new Date(item?.Tour_Plan__r?.Date__c);
    //           const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    // const end = endDate ? new Date(endDate) : new Date('2100-01-01');
    //           return filterEmpId ?
    //             (itemDate >= start && itemDate <= end && filterEmpId === item?.Tour_Plan__r?.Contact__r?.EmpId__c) :
    //             (itemDate >= start && itemDate <= end);
    //         });

    //         const sortedData = filteredData.sort((a, b) => new Date(b?.Tour_Plan__r?.Date__c) - new Date(a?.Tour_Plan__r?.Date__c));

    //         setActualPlanned(sortedData);
    //       } catch (error) {
    //         console.error('Error fetching actual vs planned data:', error);
    //       } finally {
    // setLoading(false);
    //       }
    //     };

    //     fetchData();
    //   }, [EmployeeId, accessToken, designation, filterEmpId, startDate, endDate]);

    // const fetchData = async () => {
    // setLoading(true); // Start loading state

    //     try {
    //         // Fetch data from API
    //         const response = await reportPOBReportDownloadApi(
    //             accessToken,
    // filterEmpId,
    // startDate,
    //             endDate,
    //             designation,
    // empId,
    //             '4000'
    //         );

    //         // Log the entire response to understand its structure
    //         console.log("API Response:", JSON.parse(response.data));

    //         // Check if response and response.data.DCR are defined and have records
    //         // if (!response || !response.data || !Array.isArray(response.data.records)) {
    //         //     console.error("Unexpected response format:", response);
    //         //     throw new Error("Invalid response format");
    //         // }

    //         // Access the array of DCRs
    //         const dataArray = JSON.parse(response.data);
    //         console.log("Fetched Data Array:", dataArray);

    //         // Set default dates if not provided
    //         const start = startDate ? new Date(startDate) : new Date("1900-01-01");
    //         const end = endDate ? new Date(endDate) : new Date("2100-01-01");
    // start.setHours(0, 0, 0, 0);
    // end.setHours(23, 59, 59, 999);

    //         // Filter and sort data
    //         const filteredData = dataArray.filter((item) => {
    //             const itemDate = new Date(item?.Date__c);
    //             return itemDate >= (start && itemDate <= end) ||
    //                 (!filterEmpId || filterEmpId === item?.Tour_Plan__r?.Contact__r?.EmpId__c);
    //         });

    //         console.log("Filtered Data:", filteredData);

    //         const sortedData = filteredData.sort(
    //             (a, b) => new Date(b?.Date__c) - new Date(a?.Date__c)
    //         );

    //         console.log("Sorted Data:", sortedData);

    //         // Update state
    //         setActualPlanned(sortedData);
    //         setDataFetched(sortedData.length > 0); // Set dataFetched based on the presence of data

    //     } catch (error) {
    //         console.error("Error fetching DCR data:", error);
    //         setDataFetched(false); // Indicate no data was fetched in case of error
    //     } finally {
    // setLoading(false); // End loading state
    //     }
    // };

    const fetchData = async (loadMore = false, id = '') => {
        setLoading(true); // Start loading state

        try {
            // Fetch data from API, use the id (lastRecordId) when loading more
            const response = await reportPOBReportDownloadApi(
                accessToken,
                filterEmpId,
                startDate,
                endDate,
                designation,
                empId,
                loadMore ? id : '',  // Pass the id (lastRecordId) if loading more
                division
            );

            // Parse the response data
            const dataArray = JSON.parse(response.data);
            console.log("API Response:", dataArray);

            // Check if data and records are available
            if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
                setActualPlanned([]); // Reset data to an empty array
                setHasMore(false); // No more records to fetch
                return;
            }

            const { records, nextLastRecordId } = dataArray;

            if (loadMore) {
                // Append new records to the existing data
                setActualPlanned((prevData) => [...prevData, ...records]);
            } else {
                // Set data for the first load
                setActualPlanned(records);
            }

            // Update the lastRecordId for pagination
            setLastRecordId(nextLastRecordId);

            // If there are more records, allow loading more
            setHasMore(!!nextLastRecordId);

            console.log("Fetched Records:", records);

            // Set dataFetched based on the presence of data
            setDataFetched(records.length > 0);
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error("Provided employee code is not a valid subordinate.");
                setActualPlanned([]); // Reset data to an empty array
            } else {
                console.error("Error fetching tour plans:", error);
                setActualPlanned([]); // Reset data to an empty array in case of other errors
            }
            setDataFetched(false); // Indicate no data was fetched in case of error
        } finally {
            setLoading(false); // End loading state
        }
    };

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            setError('Please enter only numbers.');
        } else {
            setError('');
        }
    };

    const exportToExcel = async () => {
        let allData = [];  // Array to store all the fetched data
        let lastRecordId = '';  // Keep track of the last fetched record ID
        let hasMore = true;  // Boolean to indicate if more records are available
        const limit = 500;  // Limit for the number of records per API call

        setExcloading(true);  // Show loading indicator during export process

        try {
            // Loop until all data is fetched
            while (hasMore) {
                // Fetch a chunk of data
                const response = await reportPOBReportDownloadApi(
                    accessToken,
                    filterEmpId,
                    startDate,
                    endDate,
                    designation,
                    empId,
                    lastRecordId,  // Use lastRecordId to fetch the next batch of records
                    division
                );

                // Parse the response
                const dataArray = JSON.parse(response.data);

                // If no records are returned, stop fetching
                if (!dataArray || !dataArray.records || dataArray.records.length === 0) {
                    setActualPlanned([]); // Reset data to an empty array
                    hasMore = false;
                    break;
                }

                // Extract records and the next lastRecordId from the response
                const { records, nextLastRecordId } = dataArray;

                // Merge the new records into allData
                allData = [...allData, ...records];

                // Update the lastRecordId for the next API call
                lastRecordId = nextLastRecordId;

                // If there are no more records, stop the loop
                hasMore = !!nextLastRecordId;
            }

            // After fetching all records, export to Excel
            if (allData.length > 0) {
                // Generate Excel file from allData
                generateExcelFile(allData);
            } else {
                console.log('No data available to export');
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error("Provided employee code is not a valid subordinate.");
                setActualPlanned([]); // Reset data to an empty array
            } else {
                console.error("Error fetching tour plans:", error);
                setActualPlanned([]); // Reset data to an empty array in case of other errors
            }
        } finally {
            setExcloading(false);  // Hide loading indicator after export process
        }
    };

    const generateExcelFile = (allactualPlanned) => {
        try {
            // Prepare data for the worksheet
            const data = allactualPlanned.map(item => ({
                'Call Date': formatDateStringDDMMYYYY(item?.Tour_Plan__r?.Date__c) || 'N/A',
                'Employee Code': item?.Tour_Plan__r?.Contact__r?.EmpId__c || 'N/A',
                'Employee Name': item?.Tour_Plan__r?.Contact__r?.Name || 'N/A',
                'Joint Work Emp Code': item?.Tour_Plan__r?.Tour_Plan_Approval__r?.EmpId__c || '-',
                'Joint Work Emp Name': item?.Tour_Plan__r?.Tour_Plan_Approval__r?.Name || '-',
                'Route No.': item?.Tour_Plan__r?.Route__c || '-',
                'Planned POB': item?.Tour_Plan__r?.FixedPOB__c || '0.00',
                'Customer Name': item?.Tour_Plan__r?.Customer__r?.Name || 'N/A',
                'Actual POB': item?.POB__c || '0.00',
            }));

            const ws = XLSX.utils.json_to_sheet(data); // Convert JSON data to worksheet
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Plan Vs Actual'); // Add worksheet to workbook
            XLSX.writeFile(wb, 'Plan_Actual_Report.xlsx'); // Save workbook as Excel file

        } catch (error) {
            console.error('Error generating Excel file:', error);
            alert('Failed to generate Excel file. Please check the console for details.');
        }
    };

    const handleSubmit = () => {
        fetchData();
    };

    const handleLoadMore = () => {
        if (hasMore) {
            fetchData(true, lastRecordId); // Pass true for load more and the lastRecordId
        }
    };

    return (
        <>
            {excloading && <Loader />}
            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4 text-center">Planned Vs Actual Report</h4>
                            <div className="filter-section mb-4">
                                <label style={{ marginRight: '1rem' }}>
                                    Start Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        value={startDate}
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        onChange={e => setStartDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    End Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    EmpId
                                    <input
                                        className="CommonDropdown form-select"
                                        type="text"
                                        max={4}
                                        style={{
                                            width: "8rem",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={filterEmpId}
                                        onChange={e => setFilterEmpId(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </label>
                                <span onClick={handleSubmit}>
                                    <CommonButton title={"Submit"} className="btn btn-primary" />
                                </span>
                                {dataFetched && (
                                    <span onClick={exportToExcel} style={{ marginLeft: "10px" }}>
                                        <CommonButton
                                            title={'Download Excel'}
                                            className="btn btn-primary"
                                        />
                                    </span>
                                )}
                            </div>
                            {error && <span className="dcr-form-errors">{error}</span>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                dataFetched && (
                                    <div
                                        id="table-to-xls"
                                        className="table-wrapper-scroll-y custom-scrollbar"
                                        style={{ padding: '20px', overflow: 'auto' }}
                                    >
                                        <table
                                            className="w-100"
                                            style={{ textAlign: "center" }}
                                            ref={ref}
                                        >
                                            <thead>
                                                <tr className="ladder_heading" >
                                                    <th className="custom-header">Call Date</th>
                                                    <th className="custom-header">Employee Code</th>
                                                    <th className="custom-header">Employee Name</th>
                                                    <th className="custom-header">Joint Work Emp Code</th>
                                                    <th className="custom-header">Joint Work Emp Name</th>
                                                    <th className="custom-header">RouteNo.</th>
                                                    <th className="custom-header">Planned POB</th>
                                                    <th className="custom-header">Customer Name</th>
                                                    <th className="custom-header">Actual POB</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {actualPlanned?.map((data, index) => (
                                                    <tr key={index} className="ladder_heading_data">
                                                        <td className='pl-3'>{formatDateStringDDMMYYYY(data?.Tour_Plan__r?.Date__c)}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Contact__r?.EmpId__c}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Contact__r?.Name}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Tour_Plan_Approval__r?.EmpId__c}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Tour_Plan_Approval__r?.Name}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Route__c}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.FixedPOB__c}</td>
                                                        <td className='pl-3'>{data?.Tour_Plan__r?.Customer__r?.Name}</td>
                                                        <td className='pl-3'>{data?.POB__c}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            )}
                        </Card.Body>
                    </Card>
                    <ToastContainer />

                    {(hasMore && !isLoading && (actualPlanned.length > 0)) &&
                        <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
                            <CommonButton title={"Load More"} />
                        </span>
                    }
                </div>
            </div>
        </>
    );
});

export default ActualPlannedReport;